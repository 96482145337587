import React from "react";
import Brand from "components/Brand";
import HeroImage from "components/HeroImage";
import NotOwnVehicleMessage from "./NotOwnVehicleMessage";
import { Api } from "constants/api";
import { AuthConsumer } from "components/AuthContext";

class NotOwnVehiclePage extends React.Component {
  componentWillUnmount() {
    this.props.logout();
  }

  render() {
    const userName = sessionStorage.getItem("firstName");
    const dealerPhoneNumber = sessionStorage.getItem("dealerPhoneNumber");
    const dealerName = sessionStorage.getItem("dealerName");
    const logoImage = sessionStorage.getItem("logoImage");
    const heroImage = sessionStorage.getItem("heroImage");
    const isLogoLabelVisible = JSON.parse(
      sessionStorage.getItem("isLogoLabelVisible") || false
    );

    return (
      <div className="home">
        <div className="responsive-wrapper">
          <main>
            <Brand
              logo={Api.Img.Url + logoImage}
              dealerName={dealerName}
              isLogoLabelVisible={isLogoLabelVisible}
            />
            <HeroImage img={Api.Img.Url + heroImage} />
            <section className="l-grid grid-gap-8">
              <NotOwnVehicleMessage
                userName={userName}
                dealerPhoneNumber={dealerPhoneNumber}
              />
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default props => (
  <AuthConsumer>
    {({ isAuthenticated, logout }) => (
      <NotOwnVehiclePage {...props} logout={logout} />
    )}
  </AuthConsumer>
);
