import React from "react";

const WrenchSvg = props => (
  <svg viewBox="0 0 27 28" width={27} height={28} {...props}>
    <title>{"wrench"}</title>
    <g fillRule="nonzero">
      <path d="M26.119 2.468l-1.63-1.63C23.429-.222 21.639-.284 20.5.702l-2.862 2.482a.461.461 0 0 0-.093.11l-2.786 4.642-1.79.596a.46.46 0 0 0-.18.762l4.877 4.876a.46.46 0 0 0 .761-.18l.597-1.79 4.71-2.844a.46.46 0 0 0 .11-.093l2.423-2.807c.98-1.137.914-2.926-.147-3.987z" />
      <path d="M24.815 18.472a5.059 5.059 0 0 1 1.133 5.424.46.46 0 0 1-.753.157l-2.924-2.924-1.95 1.95 2.924 2.925a.46.46 0 0 1-.158.753 5.056 5.056 0 0 1-5.423-1.133 5.059 5.059 0 0 1-1.236-5.136l-3.841-3.842-4.55 4.55L6.42 24.09a.46.46 0 0 1-.195.186l-2.6 1.3a.459.459 0 0 1-.532-.085l-1.625-1.626a.46.46 0 0 1-.086-.53l1.3-2.601a.46.46 0 0 1 .187-.196l2.894-1.618 4.55-4.55-3.654-3.653a5.059 5.059 0 0 1-6.27-6.659.46.46 0 0 1 .754-.157l2.924 2.924 1.95-1.95-2.924-2.924a.46.46 0 0 1 .158-.754 5.059 5.059 0 0 1 6.659 6.27l9.77 9.77a5.059 5.059 0 0 1 5.136 1.235zm-7.473 1.642a.28.28 0 0 0-.01-.012l-4.717-4.673-5.113 5.083a.512.512 0 0 0-.086.113l-1.771 3.15-2.442 1.213-1.297-1.29 1.22-2.427 3.17-1.76a.514.514 0 0 0 .112-.086l5.112-5.081-4.437-4.398a.463.463 0 0 0-.496-.103c-1.542.604-3.296.132-4.467-1.039-.994-.995-1.355-2.41-1.087-3.758l2.72 2.72c.18.181.474.181.655 0L6.972 5.15c.18-.18.085-.31-.095-.49L4.12 1.755c1.349-.268 2.894.165 3.889 1.16 1.17 1.17 1.643 2.871 1.039 4.414a.463.463 0 0 0 .103.496L19.3 18.022c.13.13.325.17.496.104 1.543-.605 3.29-.135 4.462 1.036.995.995 1.395 2.467 1.127 3.816l-2.738-2.718c-.18-.181-.442-.241-.623-.06l-2.512 2.564c-.087.087-.347.31-.241.38.095.063.059.206.113.298l.143.143 2.706 2.554c-1.35.268-2.87-.093-3.864-1.088-1.171-1.17-1.641-2.918-1.037-4.46.044-.113.086-.23.074-.336a.46.46 0 0 0-.063-.141z" />
    </g>
  </svg>
);

export default WrenchSvg;
