import React, { Component } from "react";
import moment from "moment";
import StepHeader from "components/Header/StepHeader";
import ChooseAdvisor from "containers/AppointmentPage/ChooseAdvisor";
import SelectTransportation from "containers/AppointmentPage/SelectTransportation";
import DayPicker from "containers/AppointmentPage/DayPicker";
import TimePicker from "containers/AppointmentPage/TimePicker";
import ActionButton from "components/ActionButton";
import Enums from "constants/enum";
import fetchAppointmentSlots from "./fetchAppointmentSlots";
import fetchServiceAdvisors from "./fetchServiceAdvisors";
import fetchAppointmentOptionsAndDates from "./fetchAppointmentOptionsAndDates";
import { saveData } from "containers/AppointmentPage/saveData";
import WithLoader from "components/WithLoader/index";
import WithErrorPage from "components/WithErrorPage";

export default class AppointmentPage extends Component {
  state = {
    isLoading: true,
    isError: false,
    transportMethodData: null,
    selectedTransportMethod: "",
    workCapacities: null,
    dateData: null,
    selectedDate: null,
    datePickerKey: 0,
    timeSlotData: null,
    selectedTimeSlot: null,
    predefinedTimeSlotOptions: null,
    advisorData: null,
    selectedAdvisorId: "",
    selectedDateTimeSlotsNotAvailable: false
  };

  initializeTransportMethodState = data => {
    if (
      data.Option &&
      data.Option.length &&
      data.WorksDiaryDetails &&
      data.WorksDiaryDetails.length
    ) {
      const transportMethodData = Object.assign(
        {},
        ...data.Option.map(item => ({ [item.OptionID]: item }))
      );
      const transportMethodHistoryValue = sessionStorage.getItem(
        "transportMethod"
      );
      const transportMethodInitialValue =
        transportMethodHistoryValue &&
        Object.keys(transportMethodData).includes(transportMethodHistoryValue)
          ? transportMethodHistoryValue
          : Object.keys(transportMethodData)[0];
      const workCapacities = Object.assign(
        {},
        ...data.WorksDiaryDetails.map(item => ({
          [item.Date]: item.CapacityFree
        }))
      );
      const predefinedTimeSlotOptions = data.PredefinedTimeSlots;

      this.setState(
        {
          transportMethodData,
          workCapacities,
          predefinedTimeSlotOptions
        },
        () => this.onSetSelectedTransportMethod(transportMethodInitialValue)
      );
    }
  };

  initializeDayPickerState = () => {
    const slotArrayData = this.state.transportMethodData[
      this.state.selectedTransportMethod
    ].Slots;
    const minimumFreeCapacity = sessionStorage.getItem("minimumFreeCapacity");

    if (slotArrayData && slotArrayData.length) {
      const dateData = Object.assign(
        {},
        ...slotArrayData.map(slot => {
          if (
            moment().isBefore(slot.Date, "day") &&
            this.state.workCapacities[slot.Date] &&
            this.state.workCapacities[slot.Date] >= minimumFreeCapacity
          ) {
            // All dates are set to DateAvailabilityType.Full
            const availabilityType = Enums.DateAvailabilityType.Full;
            return {
              [slot.Date]: {
                timeSlots: slot.Slots,
                availabilityType: availabilityType
              }
            };
          }
        })
      );

      const selectedDateHistoryValue = sessionStorage.getItem("jobDate");
      const selectedDateInitialValue =
        selectedDateHistoryValue &&
        Object.keys(dateData).includes(selectedDateHistoryValue)
          ? selectedDateHistoryValue
          : null;
      this.setState({ dateData: dateData }, () => {
        this.onSetSelectedDate(selectedDateInitialValue);
        this.setState({ datePickerKey: this.state.datePickerKey + 1 });
      });
    }
  };

  initializeTimePickerState = () => {
    const timeSlots =
      this.state.dateData && this.state.selectedDate
        ? this.state.dateData[this.state.selectedDate].timeSlots
        : null;
    if (timeSlots && timeSlots.length) {
      const selectedTimeSlotHistoryValue = sessionStorage.getItem("timeSlot");
      const selectedTimeSlotInitialValue =
        selectedTimeSlotHistoryValue &&
        timeSlots.includes(selectedTimeSlotHistoryValue)
          ? selectedTimeSlotHistoryValue
          : timeSlots[0];
      this.setState(
        {
          timeSlotData: timeSlots
        },
        () => this.onSetSelectedTimeSlot(selectedTimeSlotInitialValue)
      );
    } else {
      if (this.state.selectedDateTimeSlotsNotAvailable) {
        this.setState({
          selectedDateTimeSlotsNotAvailable: false,
          timeSlotData: []
        });
      } else if (this.state.selectedDate !== null && timeSlots !== null) {
        this.setState({ isLoading: true });
        fetchAppointmentSlots(this.state.selectedDate)
          .then(response => this.onReceiveAppointmentSlotsResponse(response))
          .catch(() => this.onAppointmentSlotsError());
      }
    }
  };

  initializeAdvisorListState = data => {
    if (data.Results && data.Results.AdvisorData) {
      const advisorData = Object.assign(
        {},
        ...data.Results.AdvisorData.map(item => ({ [item.AdvisorID]: item }))
      );
      const selectedAdvisorIdHistoryValue = sessionStorage.getItem("advisorId");
      sessionStorage.setItem("advisorId", "");
      const selectedAdvisorIdInitialValue =
        selectedAdvisorIdHistoryValue &&
        Object.keys(advisorData).includes(selectedAdvisorIdHistoryValue)
          ? selectedAdvisorIdHistoryValue
          : "";
      this.setState(
        {
          advisorData
        },
        () => this.onSetSelectedAdvisorId(selectedAdvisorIdInitialValue)
      );
    }
  };

  onReceiveAppointmentSlotsResponse = response => {
    if (response.Option && response.Option.length > 0) {
      const SlotsForSelectedTransportationOption = response.Option.find(
        x => x.OptionID === this.state.selectedTransportMethod
      );
      this.setState(state => {
        let newDateData = Object.assign({}, state.dateData);
        let newDateSlot =
          SlotsForSelectedTransportationOption &&
          Array.isArray(SlotsForSelectedTransportationOption.Slots) &&
          SlotsForSelectedTransportationOption.Slots.length > 0
            ? SlotsForSelectedTransportationOption.Slots[0]
            : null;
        if (newDateSlot !== null) {
          newDateData[newDateSlot.Date].timeSlots = newDateSlot.Slots.slice();
          return {
            dateData: newDateData,
            isLoading: false
          };
        } else {
          return {
            selectedDateTimeSlotsNotAvailable: true,
            isLoading: false,
            selectedTimeSlot: null
          };
        }
      }, this.initializeTimePickerState);
    } else {
      this.setState(
        {
          selectedDateTimeSlotsNotAvailable: true,
          isLoading: false,
          selectedTimeSlot: null
        },
        this.initializeTimePickerState
      );
    }
  };

  onAppointmentSlotsError = () => {
    this.setState({
      isLoading: false,
      isError: true
    });
  };

  onReceiveAppointmentOptionsAndDatesResponse = response => {
    this.setState({ isLoading: false });
    this.initializeTransportMethodState(response);
  };

  onAppointmentOptionsAndDateError = () => {
    this.setState({
      isLoading: false,
      isError: true
    });
  };

  onReceiveServiceAdvisorsResponse = response => {
    this.initializeAdvisorListState(response);
  };

  onSetSelectedTransportMethod = selectedTransportMethod => {
    this.setState({ selectedTransportMethod }, this.initializeDayPickerState);
  };

  onSetSelectedDate = selectedDate => {
    this.setState({ selectedDate: selectedDate }, () => {
      this.initializeTimePickerState();
      sessionStorage.setItem("jobDate", selectedDate);
    });
  };

  onSetSelectedTimeSlot = selectedTimeSlot => {
    const isAdvisorsVisible = JSON.parse(
      sessionStorage.getItem("isAdvisorsVisible") || null
    );
    if (isAdvisorsVisible) {
      const requestInput = {
        selectedDate: this.state.selectedDate,
        selectedTransportMethod: this.state.selectedTransportMethod,
        selectedTimeSlot
      };
      this.setState(
        { selectedTimeSlot, advisorData: null },
        fetchServiceAdvisors(
          requestInput,
          this.onReceiveServiceAdvisorsResponse
        )
      );
    } else {
      this.setState({ selectedTimeSlot });
    }
    sessionStorage.setItem("timeSlot", selectedTimeSlot);
  };

  onSetSelectedAdvisorId = selectedAdvisorId => {
    this.setState({ selectedAdvisorId });
  };

  componentDidMount() {
    fetchAppointmentOptionsAndDates()
      .then(response =>
        this.onReceiveAppointmentOptionsAndDatesResponse(response)
      )
      .catch(() => {
        this.onAppointmentOptionsAndDateError();
      });
  }

  GetTopTransportationOptionsCount = () => {
    var dealerTopTransportationOptionsCount = sessionStorage.getItem(
      "topTransportationOptionsCount"
    );
    var parsed = parseInt(dealerTopTransportationOptionsCount, 10);
    return isNaN(parsed) ? 3 : parsed;
  };

  render() {
    const isTransportationOptionsVisible = JSON.parse(
      sessionStorage.getItem("isTransportationOptionsVisible") || null
    );
    const isTimeslotsVisible = JSON.parse(
      sessionStorage.getItem("isTimeslotsVisible") || null
    );
    const isAdvisorsVisible = JSON.parse(
      sessionStorage.getItem("isAdvisorsVisible") || null
    );
    const topTransportationOptionsCount = this.GetTopTransportationOptionsCount();
    const {
      isLoading,
      isError,
      transportMethodData,
      selectedTransportMethod,
      dateData,
      selectedDate,
      timeSlotData,
      selectedTimeSlot,
      advisorData,
      selectedAdvisorId,
      datePickerKey,
      predefinedTimeSlotOptions
    } = this.state;

    return (
      <WithErrorPage isError={isError}>
        <StepHeader
          headerTitle="Appointment"
          goBackLink="/bookService/selectServices"
          stepNumber={Enums.ProgressBarStepNumber.Appointment}
        />
        <div className="responsive-wrapper">
          <WithLoader isLoading={isLoading}>
            <main>
              <section className="full-width">
                <div className="card">
                  <SelectTransportation
                    data={transportMethodData}
                    selectedValue={selectedTransportMethod}
                    onSetSelectedValue={this.onSetSelectedTransportMethod}
                    isVisible={isTransportationOptionsVisible}
                    topTransportationOptionsCount={
                      topTransportationOptionsCount
                    }
                  />
                  <DayPicker
                    datePickerKey={datePickerKey}
                    dateData={dateData}
                    selectedDate={selectedDate}
                    onSetSelectedDate={this.onSetSelectedDate}
                  />
                  <TimePicker
                    timeSlotData={timeSlotData}
                    selectedTimeSlot={selectedTimeSlot}
                    onSetSelectedTimeSlot={this.onSetSelectedTimeSlot}
                    isVisible={isTimeslotsVisible}
                    predefinedTimeSlotOptions={predefinedTimeSlotOptions}
                  />
                  <ChooseAdvisor
                    advisorData={advisorData}
                    selectedAdvisorId={selectedAdvisorId}
                    onSetSelectedAdvisorId={this.onSetSelectedAdvisorId}
                    isVisible={isAdvisorsVisible}
                  />
                </div>
              </section>
              <section>
                <div className="fixed-footer">
                  <div className="responsive-wrapper">
                    <ActionButton
                      link="/bookService/review"
                      onClick={() => saveData(this.state)}
                      isEnabled={
                        this.state.selectedTransportMethod &&
                        this.state.selectedDate &&
                        this.state.selectedTimeSlot &&
                        !isLoading
                      }
                      text="Next"
                    />
                  </div>
                </div>
              </section>
            </main>
          </WithLoader>
        </div>
      </WithErrorPage>
    );
  }
}
