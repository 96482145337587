import { ApiSource, Api } from "constants/api";
import { appendSlashToUrl } from "utils/urlUtils";
import { logError } from "utils/logUtils";

export const fetchApi = (
  apiSource,
  endpoint,
  body = undefined,
  signal = undefined
) => {
  const { url, headers } = getUrlAndHeaders(apiSource, endpoint);
  const options = {
    method: body === undefined ? "GET" : "POST",
    headers,
    body: body === undefined ? undefined : JSON.stringify(body),
    signal: signal
  };

  return fetch(url, options)
    .then(response => {
      if (!response.ok) {
        try {
          return response.text().then(text => {
            if (text.length) {
              const logErrorMessage = `${text} on fetching ${endpoint} `;
              logError(logErrorMessage);
              throw new Error(JSON.parse(text));
            }
            logAndThrowError(
              response.status + " " + response.statusText,
              endpoint
            );
          });
        } catch (error) {
          logAndThrowError(
            response.status + " " + response.statusText,
            endpoint
          );
        }
      }
      return response.text();
    })
    .then(res => {
      return res.length ? JSON.parse(res) : {};
    });
};

const logAndThrowError = (errorMessage, endpoint) => {
  const logErrorMessage = `${errorMessage} on fetching ${endpoint} `;
  logError(logErrorMessage);
  throw new Error(errorMessage);
};

const getUrlAndHeaders = (apiSource, endpoint) => {
  let url = "";
  let headers = {};

  switch (apiSource) {
    case ApiSource.CustomerVehicle:
      url = appendSlashToUrl(Api.CustomerVehicle.Url) + endpoint;
      headers = {
        "Content-Type": "application/json",
        "x-functions-key": Api.CustomerVehicle.XFunctionsKey
      };
      break;

    case ApiSource.DealerConfiguration:
      url = appendSlashToUrl(Api.DealerConfiguration.Url) + endpoint;
      headers = {
        "Content-Type": "application/json",
        "x-functions-key": Api.DealerConfiguration.XFunctionsKey
      };
      break;

    case ApiSource.VehicleMaintenance:
      url = appendSlashToUrl(Api.VehicleMaintenance.Url) + endpoint;
      headers = {
        "Content-Type": "application/json",
        "x-functions-key": Api.VehicleMaintenance.XFunctionsKey
      };
      break;

    case ApiSource.Appointment:
      url = appendSlashToUrl(Api.Appointment.Url) + endpoint;
      headers = {
        "Content-Type": "application/json",
        "x-functions-key": Api.Appointment.XFunctionsKey
      };
      break;

    case ApiSource.VehicleLookup:
      url = appendSlashToUrl(Api.VehicleLookup.Url) + endpoint;
      headers = {
        "Content-Type": "application/json",
        "x-functions-key": Api.VehicleLookup.XFunctionsKey
      };
      break;

    default:
      break;
  }

  return { url, headers };
};
