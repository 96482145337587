import React from "react";

const WelcomeMessage = props => {
  const { dealerName, userName } = props;
  return (
    <React.Fragment>
      <h2>
        {userName && typeof userName === "string" && userName.trim().length > 0
          ? `Hi ${userName.trim()}`
          : "Welcome"}
      </h2>
      <p className="extra-padding">
        Welcome to {dealerName}. Click on the button below to schedule your car
        service with us.
      </p>
    </React.Fragment>
  );
};

export default WelcomeMessage;
