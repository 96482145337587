import { fetchApi } from "utils/apiFetchWrapper";
import { validateEmail } from "utils/emailUtils";
import { Api, ApiSource } from "constants/api";
import Contact from "models/contact";

export const updateCustomerContact = (data, onReceiveResponse) => {
  const vehicleNo = sessionStorage.getItem("vehicleNo");
  const contact = Contact(); // populates from session storage
  const request = {
    DealerId: data.dealerId,
    CustomerNo: data.customerNo,
    FirstName: contact.firstName.trim(),
    SurName: contact.surname.trim(),
    CustomerEmail: contact.email,
    PhoneNumber: contact.phoneNumber,
    AdditionalComments: contact.additionalComments.trim(),
    VehicleNo: vehicleNo
  };

  return fetchApi(
    ApiSource.CustomerVehicle,
    Api.CustomerVehicle.Endpoint.UpdateCustomerContact,
    request
  ).then(() => {
    onReceiveResponse();
  });
};

export const validation = {
  isNotEmpty(value) {
    return Boolean(value);
  },
  isEmail(email) {
    return validateEmail(email);
  }
};
