import React from "react";
import WrenchSvg from "components/Svg/WrenchSvg";
import ShoppingCartSvg from "components/Svg//ShoppingCartSvg";
import TickSvg from "components/Svg/TickSvg";

const AdditionalServiceItem = props => {
  const { title, isSelected, onClickAddToCartButton } = props;

  return (
    <a className="card" onClick={onClickAddToCartButton}>
      <WrenchSvg className="title-icon" />
      <h4 className="title">{title}</h4>
      <div className="add-to-cart-button">
        {isSelected ? (
          <TickSvg key={"tick"} />
        ) : (
          <ShoppingCartSvg key={"shoppingCart"} />
        )}
      </div>
    </a>
  );
};

const AdditionalServiceItemList = props => {
  const { data, selectedServices, onSetSelectedServices } = props;

  if (data.length === 0) {
    return (
      <div className="card">
        <WrenchSvg className="title-icon" />
        <h4 className="title">No options available.</h4>
      </div>
    ); //TODO: styling
  }

  const itemList = data.map((item, index) => (
    <AdditionalServiceItem
      key={index}
      title={item.Name}
      isSelected={selectedServices.includes(item.ServiceCode)}
      onClickAddToCartButton={() => onSetSelectedServices(item.ServiceCode)}
    />
  ));
  return <React.Fragment>{itemList}</React.Fragment>;
};

const AdditionalServices = props => {
  const { isVisible, data, selectedServices, onSetSelectedServices } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <section className="additional-services">
      <h3 className="text-center">Please Select Additional Services</h3>
      <AdditionalServiceItemList
        data={data}
        selectedServices={selectedServices}
        onSetSelectedServices={onSetSelectedServices}
      />
    </section>
  );
};

export default AdditionalServices;
