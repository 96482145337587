import { validateEmail } from "utils/emailUtils";

// Data model component for the user contact info. Uses session storage to actually store the data.
// Each call to this function will produce a new object populated from the session storage.
// Edits in this object will not be reflected in the session storage until explicitly saved by
// save() or update(,, true)

export default function() { return {

  _firstName:          sessionStorage.getItem("firstName") || "",
  _surname:            sessionStorage.getItem("surname") || "",
  _email:              sessionStorage.getItem("email") || "",
  _phoneNumber:        sessionStorage.getItem("phoneNumber") || "",
  _additionalComments: sessionStorage.getItem("additionalComments") || "",
  
  get firstName(){ return this._firstName; },
  set firstName(n) {this. _firstName = n; },

  get surname(){ return this._surname; },
  set surname(n) { this._surname = n; },

  get email(){ return this._email; },
  set email(n) {this._email = n.trim(); },

  get phoneNumber(){ return this._phoneNumber; },
  set phoneNumber(n) { this._phoneNumber = n.trim(); },

  get additionalComments(){ return this._additionalComments; },
  set additionalComments(n) { this._additionalComments = n; },

  get firstNameError(){ return !this.firstName; },
  get surnameError(){ return !this.surname; },
  get emailError(){ return !validateEmail(this.email); },
  get phoneNumberError(){ return false; },                //TODO: do we validate the phone number?
  get additionalCommentsError(){ return false; }, 

  get isError(){ return this.firstNameError || this.surnameError || this.emailError || this.phoneNumberError || this.additionalCommentsError; },

  save: function(){
    sessionStorage.setItem("firstName", this._firstName);
    sessionStorage.setItem("surname", this._surname);
    sessionStorage.setItem("email", this._email);
    sessionStorage.setItem("phoneNumber", this._phoneNumber);
    sessionStorage.setItem("additionalComments", this._additionalComments);
  },

  update: function(name, value, saveNow){
     this[name] = value;
     if(saveNow) this.save();
     return this;
  }

};};