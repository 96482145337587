import React from "react";
import Cleave from "cleave.js/react";
import CleavePhone from "cleave.js/dist/addons/cleave-phone.i18n";
import ActionButton from "components/ActionButton";
import CustomerContactEdit from "components/CustomerContactEdit";

const ContactInformationForm = props => {
  const { data, handleSubmit, onChange } = props;

  return (
    <React.Fragment>
      <div className="card contact">
        <CustomerContactEdit
          data={data}
          onChange={onChange}
          askForComments={true}
        />
      </div>
      <ActionButton
        onClick={handleSubmit}
        isEnabled={!data.isError}
        text="Save"
      />
    </React.Fragment>
  );
};

export default ContactInformationForm;
