import React from "react";
import themes from "constants/themes";
import {
  updateThemeClassName,
  updateFavicon,
  updateBackgroundImage
} from "utils/documentObjectUtils";

const ThemeContext = React.createContext({
  theme: themes.default, // default value
  updateTheme: () => {}
});

class ThemeProvider extends React.Component {
  state = {
    theme: null
  };

  updateThemeState = appThemeName => {
    const theme =
      appThemeName in themes ? themes[appThemeName] : "theme-default";
    this.setState({ theme });
    updateThemeClassName(theme);
  };

  updateTheme = appThemeName => {
    this.updateThemeState(appThemeName);
    sessionStorage.setItem("appThemeName", appThemeName);
  };

  componentDidMount() {
    const appThemeName = sessionStorage.getItem("appThemeName");
    this.updateThemeState(appThemeName);
    const backgroundImage = sessionStorage.getItem("backgroundImage");
    updateBackgroundImage(backgroundImage);
    const favicon = sessionStorage.getItem("favicon");
    updateFavicon(favicon);
  }

  render() {
    return (
      <ThemeContext.Provider
        value={{ theme: this.state.theme, updateTheme: this.updateTheme }}
      >
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

const ThemeConsumer = ThemeContext.Consumer;

export { ThemeProvider, ThemeConsumer };
