import React from "react";
import moment from "moment";
import OutlineButton from "components/OutlineButton";

const AppointmentReview = props => {
  const { timeSlot, jobDate, advisorName, transportMethodName } = props.data;
  const isTransportationOptionsVisible = JSON.parse(
    sessionStorage.getItem("isTransportationOptionsVisible") || null
  );
  const isTimeslotsVisible = JSON.parse(
    sessionStorage.getItem("isTimeslotsVisible") || null
  );
  const isAdvisorsVisible = JSON.parse(
    sessionStorage.getItem("isAdvisorsVisible") || null
  );

  const TimeItem = (
    <div className="review-item">
      {isTimeslotsVisible ? (
        <React.Fragment>
          <h1>
            {timeSlot && timeSlot !== "null"
              ? moment(timeSlot.split("-")[0], "HH:mm").format("hh:mm A")
              : "Not Selected"}
          </h1>
          <p className="bold">{moment(jobDate).format("dddd, DD MMM YYYY")}</p>
        </React.Fragment>
      ) : (
        <h2>{moment(jobDate).format("DD MMM YYYY")}</h2>
      )}
    </div>
  );

  const AdvisorItem = (
    <div className="review-item">
      <p className="label">Advisor agent</p>
      <p className="bold">
        {advisorName && advisorName !== "null"
          ? advisorName
          : "First available Advisor"}
      </p>
    </div>
  );

  const TransportationItem = (
    <div className="review-item">
      <p className="label">Transportation</p>
      <p className="bold">{transportMethodName}</p>
    </div>
  );

  return (
    <React.Fragment>
      <h3 className="text-center">Appointment Review</h3>
      <div className="card card-full-width text-center">
        <div className="review-appt">
          <div className="review-items">
            {TimeItem}
            {!isAdvisorsVisible &&
              isTransportationOptionsVisible &&
              TransportationItem}
          </div>
          {isAdvisorsVisible && (
            <div className="review-items two-col">
              {AdvisorItem}
              {isTransportationOptionsVisible && TransportationItem}
            </div>
          )}
        </div>
        <div className="card__cta">
          <OutlineButton link="/bookService/appointment" text="Edit" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AppointmentReview;
