import React from "react";
import CarDetail from "components/CarDetail";
import CartList from "components/ShoppingCart/CartList";
import OutlineButton from "components/OutlineButton";

const ServiceReview = () => {
  const carDetailData = {
    modelYear: sessionStorage.getItem("modelYear"),
    modelName: sessionStorage.getItem("modelName"),
    mileage: sessionStorage.getItem("mileage"),
    isMileageVisible: JSON.parse(
      sessionStorage.getItem("isMileageVisible") || null
    ),
    odometerUnit: sessionStorage.getItem("odometerUnit")
  };

  const selectedServicesData = JSON.parse(
    sessionStorage.getItem("selectedServicesData") || []
  );
  const isPriceVisible = JSON.parse(
    sessionStorage.getItem("isPriceVisible") || null
  );
  const jobNotes = sessionStorage.getItem("jobNotes") || null;
  const currencySymbol = sessionStorage.getItem("currencySymbol");

  return (
    <React.Fragment>
      <h3 className="text-center">Service Review</h3>
      <p className="text-center text-tiny">
        Please carefully review your request below
      </p>
      <div className="card card-full-width">
        <div className="cart">
          <CarDetail data={carDetailData} />
          <CartList
            unDeletableData={selectedServicesData}
            deletableData={[]}
            isPriceVisible={isPriceVisible}
            deleteService={null}
            notes={jobNotes}
            currencySymbol={currencySymbol}
          />
        </div>
        <div className="card__cta">
          <OutlineButton link="/bookService/selectServices" text="Edit" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ServiceReview;
