import React, { Component } from "react";
import { Api, ApiSource } from "constants/api";
import { fetchApi } from "utils/apiFetchWrapper";
import QueryStringJS from "query-string";
import ActionButton from "components/ActionButton";
import WelcomeMessage from "./WelcomeMessage";
import Brand from "components/Brand";
import HeroImage from "components/HeroImage";
import { saveDealerConfiguration, saveCustomerVehicle } from "./saveToStorage";
import ErrorMessage from "./Error";
import ExistingBooking from "./ExistingBooking";
import WithLoader from "components/WithLoader";
import WithErrorPage from "components/WithErrorPage";
import { ThemeConsumer } from "components/ThemeContext";
import { AuthConsumer } from "components/AuthContext";
import {
  updateFavicon,
  updateBackgroundImage
} from "utils/documentObjectUtils";

export class WelcomePage extends Component {
  state = {
    isFetchingDealerConfiguration: false,
    isDealerConfigurationError: null,
    dealerName: "",
    dealerId: null,
    dealerPhoneNumber: "",
    heroImage: "",
    logoImage: "",
    isLogoLabelVisible: null,

    isFetchingCustomerVehicle: false,
    isCustomerVehicleError: null,
    customerVehicleErrorMessage: "",
    userName: "",
    customerNo: "",
    vehicleNo: "",

    isFetchingExistingAppointment: false,
    isExistingAppointmentError: null,
    existingBooking: null,
    gotoLookup: false
  };

  onReceiveDealerConfigurationResponse = response => {
    this.setState({
      isFetchingDealerConfiguration: false,
      isDealerConfigurationError: false,
      dealerName: response.DealerName,
      dealerId: response.DealerId,
      dealerPhoneNumber: response.PhoneNumber
        ? response.PhoneNumber.trim()
        : response.PhoneNumber,
      heroImage: response.HeroImage,
      logoImage: response.LogoImage,
      isLogoLabelVisible: response.ShowLogoLabel
    });

    this.props.updateTheme(response.AppThemeName);
    updateFavicon(response.Favicon);
    updateBackgroundImage(response.BackgroundImage);
    saveDealerConfiguration(response);

    if (this.state.customerNo && this.state.vehicleNo) {
      this.setState({ gotoLookup: false });
      sessionStorage.setItem("isNewCustomer", false);

      this.fetchExistingAppointment(
        this.state.dealerId,
        this.state.customerNo,
        this.state.vehicleNo
      );
      this.fetchCustomerVehicle(
        this.state.dealerId,
        this.state.customerNo,
        this.state.vehicleNo
      );
    } else {
      this.setState({ gotoLookup: true });
      sessionStorage.setItem("isNewCustomer", true);
    }
  };

  onReceiveDealerConfigurationError = () => {
    this.setState({
      isFetchingDealerConfiguration: false,
      isDealerConfigurationError: true
    });
  };

  onReceiveExistingAppointmentResponse = response => {
    this.setState({
      isFetchingExistingAppointment: false,
      isExistingAppointmentError: false,
      existingBooking: response
    });
  };

  onReceiveExistingAppointmentError = error => {
    this.setState({
      isFetchingExistingAppointment: false,
      isExistingAppointmentError: true,
      existingAppointmentErrorMessage: error.message
    });
  };

  onReceiveCustomerVehicleResponse = response => {
    this.setState({
      isFetchingCustomerVehicle: false,
      isCustomerVehicleError: false,
      userName: response.FirstName
    });
    saveCustomerVehicle(response);
  };

  onReceiveCustomerVehicleError = error => {
    this.setState({
      isFetchingCustomerVehicle: false,
      isCustomerVehicleError: true,
      customerVehicleErrorMessage: error.message
    });
  };

  fetchDealerConfigurationByDealerId = dealerId => {
    const endpoint = `dealers/${dealerId}`;
    this.setState({
      isFetchingDealerConfiguration: true
    });
    return fetchApi(ApiSource.DealerConfiguration, endpoint)
      .then(response => this.onReceiveDealerConfigurationResponse(response))
      .catch(e => this.onReceiveDealerConfigurationError(e));
  };

  fetchDealerConfigurationByCommunityIdAndRooftopId = (
    communityId,
    rooftopId
  ) => {
    const endpoint = `dealers?roofTopId=${rooftopId}&communityid=${communityId}`;
    this.setState({
      isFetchingDealerConfiguration: true
    });
    return fetchApi(ApiSource.DealerConfiguration, endpoint)
      .then(response => this.onReceiveDealerConfigurationResponse(response))
      .catch(e => this.onReceiveDealerConfigurationError(e));
  };

  fetchCustomerVehicle = (dealerId, customerNo, vehicleNo) => {
    const endpoint = `dealers/${dealerId}/customers/${customerNo}/vehicles/${vehicleNo}`;
    this.setState({
      isFetchingCustomerVehicle: true
    });
    return fetchApi(ApiSource.CustomerVehicle, endpoint)
      .then(response => this.onReceiveCustomerVehicleResponse(response))
      .catch(e => this.onReceiveCustomerVehicleError(e));
  };

  fetchExistingAppointment = (dealerId, customerNo, vehicleNo) => {
    const endpoint = `dealers/${dealerId}/customers/${customerNo}/vehicles/${vehicleNo}`;
    this.setState({
      isFetchingExistingAppointment: true
    });
    return fetchApi(ApiSource.Appointment, endpoint)
      .then(response => this.onReceiveExistingAppointmentResponse(response))
      .catch(e => this.onReceiveExistingAppointmentError(e));
  };

  parseQueryString = queryString => {
    const queryStringLowerCase = queryString.toLowerCase();
    const { d, cid, rid, cno, vno } = QueryStringJS.parse(queryStringLowerCase);

    if ((cno && !vno) || (!cno && vno)) return null; // both must be given or not given

    return d
      ? { dealerId: d, customerNo: cno, vehicleNo: vno }
      : cid && rid
      ? { communityId: cid, rooftopId: rid, customerNo: cno, vehicleNo: vno }
      : null;
  };

  componentDidMount() {
    const queryString = this.props.location.search;
    const parsedQueryString = this.parseQueryString(queryString);
    if (!parsedQueryString) {
      this.setState({
        isDealerConfigurationError: true
      });
    } else {
      sessionStorage.clear();
      sessionStorage.setItem("queryString", queryString);
      this.setState({
        customerNo: parsedQueryString.customerNo,
        vehicleNo: parsedQueryString.vehicleNo
      });
      parsedQueryString.dealerId
        ? this.fetchDealerConfigurationByDealerId(parsedQueryString.dealerId)
        : this.fetchDealerConfigurationByCommunityIdAndRooftopId(
            parsedQueryString.communityId,
            parsedQueryString.rooftopId
          );
    }
  }

  componentDidUpdate(_, prevState) {
    const {
      isDealerConfigurationError,
      isCustomerVehicleError,
      isExistingAppointmentError,
      existingBooking,
      gotoLookup
    } = this.state;

    const isLogin =
      (isDealerConfigurationError === false && gotoLookup) ||
      (existingBooking === null &&
        isCustomerVehicleError === false &&
        isExistingAppointmentError === false);

    const stateUpdate =
      prevState.isDealerConfigurationError !== isDealerConfigurationError ||
      prevState.isCustomerVehicleError !== isCustomerVehicleError ||
      prevState.isExistingAppointmentError !== isExistingAppointmentError ||
      prevState.gotoLookup !== gotoLookup;

    if (isLogin && stateUpdate) {
      this.props.login();
    }
  }

  render() {
    const {
      isFetchingDealerConfiguration,
      isDealerConfigurationError,
      isFetchingExistingAppointment,
      isExistingAppointmentError,
      isFetchingCustomerVehicle,
      isCustomerVehicleError,
      customerVehicleErrorMessage,
      dealerName,
      dealerPhoneNumber,
      userName,
      existingBooking,
      heroImage,
      logoImage,
      isLogoLabelVisible,
      gotoLookup
    } = this.state;

    const isLoading =
      isFetchingDealerConfiguration === true ||
      isFetchingCustomerVehicle === true ||
      isFetchingExistingAppointment === true;

    const isError =
      isDealerConfigurationError === true ||
      isCustomerVehicleError === true ||
      isExistingAppointmentError === true ||
      existingBooking !== null;

    const mainSection = () => {
      if (isCustomerVehicleError === true) {
        return (
          <ErrorMessage
            errorMessage={customerVehicleErrorMessage}
            dealerName={dealerName}
            dealerPhoneNumber={dealerPhoneNumber}
          />
        );
      }

      if (existingBooking !== null)
        return (
          <ExistingBooking
            existingBooking={existingBooking}
            dealerName={dealerName}
            dealerPhoneNumber={dealerPhoneNumber}
          />
        );

      return <WelcomeMessage dealerName={dealerName} userName={userName} />;
    };

    return (
      <WithErrorPage
        isError={isDealerConfigurationError === true}
        buttonText={null}
      >
        <div className="home">
          <div className="responsive-wrapper">
            <WithLoader isLoading={isLoading}>
              <main>
                <Brand
                  dealerName={dealerName}
                  logo={Api.Img.Url + logoImage}
                  isLogoLabelVisible={isLogoLabelVisible}
                />
                <HeroImage img={Api.Img.Url + heroImage} />
                <section className="l-grid grid-gap-8">{mainSection()}</section>
                <ActionButton
                  link={
                    gotoLookup
                      ? "/bookService/carLookup"
                      : "/bookService/carDetails"
                  }
                  isEnabled={!isLoading && !isError}
                  text="Start Now"
                />
              </main>
            </WithLoader>
          </div>
        </div>
      </WithErrorPage>
    );
  }
}

export default props => (
  <ThemeConsumer>
    {({ theme, updateTheme }) => (
      <AuthConsumer>
        {({ isAuthenticated, login }) => (
          <WelcomePage {...props} updateTheme={updateTheme} login={login} />
        )}
      </AuthConsumer>
    )}
  </ThemeConsumer>
);
