import React from "react";

const TransportMethodOption = props => {
  const { data, selectedValue, onSelectChange } = props;
  return (
    <div>
      <div className="radio">
        <input
          id={"radio-" + data.OptionID}
          name="radio"
          type="radio"
          value={data.OptionID}
          onChange={onSelectChange}
          checked={data.OptionID === selectedValue}
        />
        <label htmlFor={"radio-" + data.OptionID} className="radio-label">
          {data.OptionDisplayName}
        </label>
      </div>
      <p className="text-small">{data.OptionDescription}</p>
    </div>
  );
};

class TransportationOptions extends React.Component {
  state = {
    isOpen: false,
    isMounting: true
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const IsOtherTransportOptionSelected = props => {
      const { data, selectedValue, topTransportationOptionsCount } = props;
      const result = selectedValue
        ? !Object.keys(data)
            .slice(0, topTransportationOptionsCount)
            .includes(selectedValue)
        : false;
      return result;
    };

    if (prevState.isMounting && nextProps.selectedValue) {
      if (
        prevState.isOpen === false &&
        IsOtherTransportOptionSelected(nextProps)
      ) {
        return {
          isOpen: IsOtherTransportOptionSelected(nextProps),
          isMounting: false
        };
      }
      return {
        isOpen: false,
        isMounting: false
      };
    }
    return null;
  }

  onClickTitle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSelectChange = event => {
    const { onSetSelectedValue } = this.props;
    onSetSelectedValue(event.target.value);
  };

  render() {
    const { data, selectedValue, topTransportationOptionsCount } = this.props;
    if (Object.keys(data).length > topTransportationOptionsCount) {
      return (
        <div className="transportationOptions">
          {Object.keys(data)
            .slice(0, topTransportationOptionsCount)
            .map(optionId => {
              const optionData = data[optionId];
              return (
                <TransportMethodOption
                  key={optionId}
                  data={optionData}
                  selectedValue={selectedValue}
                  onSelectChange={this.onSelectChange}
                />
              );
            })}
          <div className="accordion">
            <div className="accordion__container">
              <a
                className={"toggle" + (this.state.isOpen ? " show" : "")}
                onClick={this.onClickTitle}
              >
                Other Transport Options
              </a>
              {this.state.isOpen && (
                <div className="inner">
                  {Object.keys(data)
                    .slice(topTransportationOptionsCount)
                    .map(optionId => {
                      const optionData = data[optionId];
                      return (
                        <TransportMethodOption
                          key={optionId}
                          data={optionData}
                          selectedValue={selectedValue}
                          onSelectChange={this.onSelectChange}
                        />
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="transportationOptions">
        {Object.keys(data).map(optionId => {
          const optionData = data[optionId];
          return (
            <TransportMethodOption
              key={optionId}
              data={optionData}
              selectedValue={selectedValue}
              onSelectChange={this.onSelectChange}
            />
          );
        })}
      </div>
    );
  }
}

const SelectTransportation = props => {
  const {
    data,
    selectedValue,
    onSetSelectedValue,
    isVisible,
    topTransportationOptionsCount
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div className="form-group">
      <label htmlFor="">Select Transportation</label>
      {data ? (
        <TransportationOptions
          data={data}
          selectedValue={selectedValue}
          onSetSelectedValue={onSetSelectedValue}
          topTransportationOptionsCount={topTransportationOptionsCount}
        />
      ) : (
        <p>No available options.</p>
      )}
    </div>
  );
};

export default SelectTransportation;
