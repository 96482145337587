import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import WelcomePage from "containers/WelcomePage";
import CarLookupPage from "containers/CarLookupPage";
import CheckCarDetailsPage from "containers/CheckCarDetailsPage";
import SelectBranchPage from "containers/SelectBranchPage";
import SelectServicesPage from "containers/SelectServicesPage";
import AppointmentPage from "containers/AppointmentPage";
import ReviewPage from "containers/ReviewPage";
import ConfirmationPage from "containers/ConfirmationPage";
import NotOwnVehiclePage from "containers/NotOwnVehiclePage";
import ContactInformation from "containers/ContactInformationPage";
import NotFoundPage from "containers/NotFoundPage";
import withTracker from "utils/withTracker";
import ScrollToTop from "components/ScrollToTop";
import { AuthProvider } from "components/AuthContext";
import ProtectedRoute from "components/ProtectedRoute";
import GeneralErrorPage from "containers/GeneralErrorPage";

const AppRouter = () => (
  <Router>
    <ScrollToTop>
      <AuthProvider>
        <Switch>
          <Route exact path="/" component={withTracker(WelcomePage)} />
          <ProtectedRoute
            exact
            path="/bookService/carLookup"
            component={withTracker(CarLookupPage)}
          />
          <ProtectedRoute
            exact
            path="/bookService/carDetails"
            component={withTracker(CheckCarDetailsPage)}
          />
          <ProtectedRoute
            exact
            path="/bookService/selectBranch"
            component={withTracker(SelectBranchPage)}
          />
          <ProtectedRoute
            exact
            path="/bookService/selectServices"
            component={withTracker(SelectServicesPage)}
          />
          <ProtectedRoute
            exact
            path="/bookService/appointment"
            component={withTracker(AppointmentPage)}
          />
          <ProtectedRoute
            exact
            path="/bookService/review"
            component={withTracker(ReviewPage)}
          />
          <ProtectedRoute
            exact
            path="/bookService/confirmation"
            component={withTracker(ConfirmationPage)}
          />
          <ProtectedRoute
            exact
            path="/bookService/notOwnVehicle"
            component={withTracker(NotOwnVehiclePage)}
          />
          <ProtectedRoute
            exact
            path="/bookService/contactInformation"
            component={withTracker(ContactInformation)}
          />
          <Route exact path="/404" component={withTracker(NotFoundPage)} />
          <Route
            exact
            path="/Error"
            component={withTracker(GeneralErrorPage)}
          />
          <Redirect to="/404" />
        </Switch>
      </AuthProvider>
    </ScrollToTop>
  </Router>
);

export default AppRouter;
