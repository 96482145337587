import { Api } from "constants/api";

export const updateFavicon = faviconName => {
  const link = document.querySelector("link[rel*='icon']");
  link.href = Api.Img.Url + faviconName;
};

export const updateBackgroundImage = imageName => {
  document.body.style.backgroundImage = `url(${Api.Img.Url + imageName})`;
};

export const updateThemeClassName = themeName => {
  document.documentElement.className = document.documentElement.className.replace(
    /(?:^|\s)theme-(\S)*(?!\S)/g,
    themeName
  );
};
