import React from "react";
import Modal from "react-modal";
import AlertImage from "assets/img/alert-image.svg";
import CancelSvg from "components/Svg/CancelSvg";

Modal.setAppElement("body");

const AlertDialogModal = props => {
  const { isOpen, closeAlert, ...rest } = props;

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="AlertDialog Modal"
      className="alert-dialog-modal"
      overlayClassName="alert-dialog-modal-overlay"
      onRequestClose={closeAlert}
      shouldCloseOnOverlayClick={true}
      appElement={document.main}
    >
      <AlertDialog closeAlert={closeAlert} {...rest} />
    </Modal>
  );
};

const AlertDialog = props => {
  const { closeAlert, onClickYes, title, message = "" } = props;

  return (
    <div className="alert card">
      <a className="modal-close" onClick={closeAlert}>
        <CancelSvg className="icon-cancel" />
      </a>
      <img className="alert__image" src={AlertImage} alt="" />

      {title && <h1>{title}</h1>}
      <p>{message}</p>

      {onClickYes ? (
        <div className="alert__button-group">
          <a
            onClick={onClickYes}
            className="button button-large button-secondary secondary-blue"
          >
            Yes, remove it
          </a>
          <a onClick={closeAlert} className="button button-large button-blue">
            No
          </a>
        </div>
      ) : (
        <div>
          <a onClick={closeAlert} className="button button-large button-blue">
            OK
          </a>
        </div>
      )}
    </div>
  );
};

export default AlertDialogModal;
