import React, { Component } from "react";
import StepHeader from "components/Header/StepHeader";
import ActionButton from "components/ActionButton";
import CarDetailsCard from "./CarDetails";
import MileageInputCard from "./MileageInput";
import { dismissVehicleOwner } from "./actions";
import WithLoader from "components/WithLoader/index";
import WithErrorPage from "components/WithErrorPage";
import AlertDialog from "components/AlertDialog";
import { Api } from "constants/api";

export default class CheckCarDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isError: false,
      mileage:
        sessionStorage.getItem("mileage") ||
        (sessionStorage.getItem("nextServiceMileage") > 0
          ? sessionStorage.getItem("nextServiceMileage")
          : ""),
      isAlertOpen: false
    };
    this.data = {
      detailData: {
        modelYear: sessionStorage.getItem("modelYear"),
        modelName: sessionStorage.getItem("modelName"),
        registrationNumber: sessionStorage.getItem("registrationNumber"),
        vehicleImage:
          Api.Img.Url + sessionStorage.getItem("defaultVehicleImage"),
        nextServiceDate: JSON.parse(
          sessionStorage.getItem("nextServiceDate") || null
        ),
        nextWOFDate: JSON.parse(sessionStorage.getItem("nextWOFDate") || null),
        vehicleInspectionTestName: sessionStorage.getItem("vehicleInspectionTestName")
      },
      dealerId: sessionStorage.getItem("dealerId"),
      customerNo: sessionStorage.getItem("customerNo"),
      vehicleNo: sessionStorage.getItem("vehicleNo"),
      isMileageVisible: JSON.parse(
        sessionStorage.getItem("isMileageVisible") || null
      ),
      odometerUnit: sessionStorage.getItem("odometerUnit")
    };
  }

  onSetMileageValue = mileage => {
    this.setState({ mileage });
  };

  saveData = () => {
    sessionStorage.setItem(
      "mileage",
      this.state.mileage || sessionStorage.getItem("nextServiceMileage")
    );
  };

  onReceiveReportResponse = () => {
    this.props.history.push("/bookService/notOwnVehicle");
  };

  onError = () => {
    this.setState({
      isSubmitting: false,
      isError: true
    });
  };

  onClickReportBtn = () => {
    this.setState({ isSubmitting: true });
    const { dealerId, customerNo, vehicleNo } = this.data;
    dismissVehicleOwner(
      dealerId,
      customerNo,
      vehicleNo,
      this.onReceiveReportResponse
    ).catch(() => {
      this.onError();
    });
  };

  openAlert = () => {
    this.setState({ isAlertOpen: true });
  };

  closeAlert = () => {
    this.setState({ isAlertOpen: false });
  };

  render() {
    const { detailData, isSubmitting, isError, isMileageVisible, odometerUnit } = this.data;
    const { mileage, isAlertOpen } = this.state;

    const queryString = sessionStorage.getItem("queryString");

    return (
      <WithErrorPage isError={isError} buttonText={null}>
        <WithLoader isLoading={isSubmitting} message="Submitting">
          <StepHeader
            headerTitle="Confirm Your Car's Details"
            goBackLink={"/" + queryString}
          />
          <div className="responsive-wrapper">
            <main>
              <section>
                <CarDetailsCard
                  detailData={detailData}
                  onClickNotMyVehicleBtn={this.openAlert}
                />
                <MileageInputCard
                  defaultValue={mileage}
                  onChange={this.onSetMileageValue}
                  isVisible={isMileageVisible}
                  odometerUnit={odometerUnit}
                />
                <ActionButton
                  link="/bookService/selectBranch"
                  onClick={this.saveData}
                  isEnabled={!isNaN(mileage)}
                  text="Book Appointment"
                />
              </section>
              <AlertDialog
                isOpen={isAlertOpen}
                closeAlert={this.closeAlert}
                onClickYes={this.onClickReportBtn}
                title={"Are you sure?"}
                message={
                  "An email will be sent to us and we will remove your data. Please call us to make any additional changes."
                }
              />
            </main>
          </div>
        </WithLoader>
      </WithErrorPage>
    );
  }
}
