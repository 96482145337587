import React from "react";
import ArrowDownSvg from "components/Svg/ArrowDownSvg";

const ChooseAdvisor = props => {
  const {
    isVisible,
    advisorData,
    selectedAdvisorId,
    onSetSelectedAdvisorId
  } = props;
  const handleSelectChange = event => {
    onSetSelectedAdvisorId(event.target.value);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="form-group">
      <label htmlFor="">Choose an Advisor</label>
      <div className="select-advisor">
        <ArrowDownSvg className="icon-arrow-down" />
        <select
          value={selectedAdvisorId}
          onChange={handleSelectChange}
          disabled={!advisorData}
        >
          <option value="" className="placeholder">
            Any advisor
          </option>
          {advisorData &&
            Object.keys(advisorData).map(advisorId => {
              const optionData = advisorData[advisorId];
              return (
                <option key={optionData.AdvisorID} value={optionData.AdvisorID}>
                  {optionData.AdvisorName}
                </option>
              );
            })}
        </select>
      </div>
    </div>
  );
};

export default ChooseAdvisor;
