import React from "react";

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  state = {
    isAuthenticated: JSON.parse(
      sessionStorage.getItem("isAuthenticated") || false
    )
  };

  login = () => {
    sessionStorage.setItem("isAuthenticated", JSON.stringify(true));
    this.setState({ isAuthenticated: true });
  };

  logout = () => {
    sessionStorage.setItem("isAuthenticated", JSON.stringify(false));
    this.setState({ isAuthenticated: false });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuthenticated: this.state.isAuthenticated,
          login: this.login,
          logout: this.logout
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
