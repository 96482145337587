import React from "react";

const Header = props => {
  const { headerLeft, headerTitle, progressBar, headerRight } = props;

  return (
    <header className="header">
      <div className="responsive-wrapper header-grid">
        <div className="header-left">{headerLeft}</div>
        <div className="header-center">
          <h2>{headerTitle}</h2>
          {progressBar}
        </div>
        <div className="header-right">{headerRight}</div>
      </div>
    </header>
  );
};

export default Header;
