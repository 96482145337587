export const saveDealerConfiguration = response => {
  sessionStorage.setItem("dealerName", response.DealerName);
  sessionStorage.setItem("dealerId", response.DealerId);
  sessionStorage.setItem("dealerPhoneNumber", response.PhoneNumber);
  sessionStorage.setItem(
    "dealerBranches",
    JSON.stringify(response.DealerBranches)
  );
  sessionStorage.setItem(
    "minimumFreeCapacity",
    response.MinimumFreeCapacity || 0
  );
  sessionStorage.setItem(
    "isTransportationOptionsVisible",
    JSON.stringify(response.ShowTransportations)
  );
  sessionStorage.setItem(
    "isAdvisorsVisible",
    JSON.stringify(response.ShowAdvisors)
  );
  sessionStorage.setItem("isPriceVisible", JSON.stringify(response.ShowPrice));
  sessionStorage.setItem(
    "isTimeslotsVisible",
    JSON.stringify(response.ShowTimeslots)
  );
  sessionStorage.setItem(
    "isMileageVisible",
    JSON.stringify(response.ShowMileage)
  );
  sessionStorage.setItem(
    "isAdditionalServicesVisible",
    JSON.stringify(response.ShowAdditionalServices)
  );
  sessionStorage.setItem(
    "isShoppingCartVisible",
    JSON.stringify(response.ShowShoppingCart)
  );
  sessionStorage.setItem("favicon", response.Favicon);
  sessionStorage.setItem("logoImage", response.LogoImage);
  sessionStorage.setItem("heroImage", response.HeroImage);
  sessionStorage.setItem("defaultVehicleImage", response.DefaultVehicleImage);
  sessionStorage.setItem("backgroundImage", response.BackgroundImage);
  sessionStorage.setItem(
    "isServiceListCategorised",
    JSON.stringify(response.ShowCategorisedServiceList)
  );
  sessionStorage.setItem(
    "topTransportationOptionsCount",
    response.TopTransportationOptionsCount
  );
  sessionStorage.setItem(
    "isLogoLabelVisible",
    JSON.stringify(response.ShowLogoLabel)
  );
  sessionStorage.setItem("vehicleLookupSource", response.VehicleLookupSource);
  sessionStorage.setItem("currencySymbol", response.CurrencySymbol);
  sessionStorage.setItem("odometerUnit", response.OdometerUnit);
  sessionStorage.setItem("vehicleInspectionTestName", response.VehicleInspectionTestName);
};

export const saveCustomerVehicle = response => {
  sessionStorage.setItem("communityId", response.CommunityId);
  sessionStorage.setItem("rooftopId", response.RooftopId);
  sessionStorage.setItem("customerId", response.CustomerLoginId);
  sessionStorage.setItem("firstName", response.FirstName);
  sessionStorage.setItem("surname", response.Surname);
  sessionStorage.setItem("email", response.CustomerEmail);
  sessionStorage.setItem("phoneNumber", response.PhoneNumber);
  sessionStorage.setItem("registrationNumber", response.RegistrationNo);
  sessionStorage.setItem("makeCode", response.MakeCode);
  sessionStorage.setItem("modelYear", response.ModelYear);
  sessionStorage.setItem("modelCode", response.ModelCode);
  sessionStorage.setItem("modelName", response.ModelDescription);
  sessionStorage.setItem("nextServiceMileage", response.NextServiceMileage);
  sessionStorage.setItem("variantCode", response.VariantCode);
  sessionStorage.setItem("customerNo", response.CustomerNo);
  sessionStorage.setItem("vehicleNo", response.VehicleNo);
  sessionStorage.setItem(
    "nextServiceDate",
    JSON.stringify(response.NextServiceDate)
  );
  sessionStorage.setItem("nextWOFDate", JSON.stringify(response.NextWofDate));
};
