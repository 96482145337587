import { fetchApi } from "utils/apiFetchWrapper";
import { Api, ApiSource } from "constants/api";
import { dayPickerOptionalDuration } from "constants/predefinedOptions";
import moment from "moment";
const fetchAppointmentOptionsAndDates = (signal = undefined) => {
  const jobCode = [
    ...JSON.parse(sessionStorage.getItem("distanceBasedServiceCodes")),
    ...JSON.parse(sessionStorage.getItem("additionalServiceCodes"))
  ];
  const dealerId = sessionStorage.getItem("dealerId");
  const customerNo = sessionStorage.getItem("customerNo");

  const data = {
    DealerId: dealerId,
    CustomerNo: customerNo,
    InitialDate: moment().format("YYYY-MM-DD"),
    EndDate: moment()
      .add(dayPickerOptionalDuration, "day")
      .format("YYYY-MM-DD"),
    JobCode: jobCode
  };

  return fetchApi(
    ApiSource.Appointment,
    Api.Appointment.Endpoint.GetAppointmentOptionsAndDates,
    data,
    signal
  );
};
export default fetchAppointmentOptionsAndDates;
