import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import * as Sentry from "@sentry/browser";
import browserUpdate from "browser-update";

// document: http://browser-update.org/
const browserUpdateConfiguration = {
  required: { e: 13, f: -5, o: -3, s: -3, c: 67 },
  insecure: true,
  mobile: false,
  api: 2019.03,
  test: false,
  l: "en",
  url: null,
  jsshowurl: "//browser-update.org/update.show.min.js",
  text:
    "Sorry your browser is not supported. Please choose Chrome or Firefox for the best experience."
};

browserUpdate(browserUpdateConfiguration);

ReactDOM.render(<App />, document.getElementById("root"));

Sentry.init({
  dsn: window.APP_DEPL_VAR.SentryDSN,
  environment: window.APP_DEPL_VAR.Env
});

// Enable Hot Reloading
if (module.hot) {
  module.hot.accept();
}
