import React, { useState } from "react";
import WrenchSvg from "components/Svg/WrenchSvg";
import ShoppingCartSvg from "components/Svg//ShoppingCartSvg";
import TickSvg from "components/Svg/TickSvg";

const UncategorisedServiceItem = props => {
  const { title, description, isSelected, onClickAddToCartButton } = props;
  const [displayCardDescription, setToggleCardDescription] = useState(false);
  return (
    <div className="card">
      <a
        className="card-anchor"
        onClick={() =>
          description ? setToggleCardDescription(!displayCardDescription) : ""
        }
      >
        <WrenchSvg className="title-icon" />
        <h4 className="title">{title}</h4>
        {description && (
          <p
            className={
              displayCardDescription
                ? "display-description"
                : "display-none-description"
            }
          >
            {description}
          </p>
        )}
      </a>
      <div className="add-to-cart-button" onClick={onClickAddToCartButton}>
        {isSelected ? (
          <TickSvg key={"tick"} />
        ) : (
          <ShoppingCartSvg key={"shoppingCart"} />
        )}
      </div>
    </div>
  );
};

const UncategorisedServiceItemList = props => {
  const { data, selectedServices, onSetSelectedServices } = props;

  if (data.length === 0) {
    return (
      <div className="card">
        <WrenchSvg className="title-icon" />
        <h4 className="title">No options available.</h4>
      </div>
    );
  }

  const itemList = data.map((item, index) => (
    <UncategorisedServiceItem
      key={index}
      title={item.Name}
      description={item.Description}
      isSelected={selectedServices.includes(item.ServiceCode)}
      onClickAddToCartButton={() => onSetSelectedServices(item.ServiceCode)}
    />
  ));
  return <React.Fragment>{itemList}</React.Fragment>;
};

const UncategorisedServices = props => {
  const { data, selectedServices, onSetSelectedServices } = props;
  return (
    <section className="uncategorised-services">
      <h3 className="text-center">Please select from the service options</h3>
      <UncategorisedServiceItemList
        data={data}
        selectedServices={selectedServices}
        onSetSelectedServices={onSetSelectedServices}
      />
    </section>
  );
};

export default UncategorisedServices;
export { UncategorisedServiceItemList, UncategorisedServiceItem };
