import React from "react";
import { Link } from "react-router-dom";

const ActionButton = props => {
  const { link, isEnabled, onClick, text } = props;

  if (props.link) {
    return (
      <Link
        to={link}
        onClick={onClick}
        className={"button button-large" + (isEnabled ? "" : " disable")}
      >
        {text}
      </Link>
    );
  }

  return (
    <a
      onClick={onClick}
      className={"button button-large" + (isEnabled ? "" : " disable")}
    >
      {text}
    </a>
  );
};

export default ActionButton;
