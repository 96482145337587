import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthConsumer } from "components/AuthContext";

const queryString = sessionStorage.getItem("queryString") || "";

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ isAuthenticated }) => (
      <Route
        render={props =>
          isAuthenticated === false ? (
            <Redirect to={"/" + queryString} />
          ) : (
            <Component {...props} />
          )
        }
        {...rest}
      />
    )}
  </AuthConsumer>
);

export default ProtectedRoute;
