import React from "react";
import iconCar from "assets/img/icon-car.svg";

const CarDetail = props => {
  const { modelYear, modelName, mileage, isMileageVisible, odometerUnit } = props.data;

  return (
    <div className="cart__car-details">
      <img className="icon-car" src={iconCar} alt="" />
      <div className="desc">
        <h4 className="model text-small extra-bold">{`${modelYear} ${modelName}`}</h4>
        {isMileageVisible && Number(mileage) > 0 && (
          <h5 className="mileage text-small light">
            {"Approx. " + Number(mileage).toLocaleString() + odometerUnit}
          </h5>
        )}
      </div>
    </div>
  );
};

export default CarDetail;
