import React from "react";
import LocationSvg from "components/Svg/LocationSvg";
import { Api } from "constants/api";

const BranchOption = props => {
  const { data, isSelected, onClick } = props;
  const {
    BranchId: branchId,
    BranchName: branchName,
    Address: address,
    Longitude: longitude,
    Latitude: latitude
  } = data;

  return (
    <div
      className={`card${isSelected ? " selected" : ""}`}
      onClick={() => onClick(branchId)}
    >
      <h3 className="branch-name text-center">{branchName}</h3>
      <div className="google-map">
        <img
          src={`https://maps.googleapis.com/maps/api/staticmap?size=490x240&zoom=13&maptype=roadmap&markers=color:red%7C${latitude},${longitude}&key=${
            Api.GoogleService.Key
          }`}
          alt=""
        />
      </div>

      <div className="address">
        <LocationSvg />
        <span>{address}</span>
      </div>
    </div>
  );
};

const BranchOptionList = props => {
  const { data, selectedBranchId, onClick } = props;

  if (!data || data.length <= 0) {
    return null;
  }

  const itemList = data.map((item, index) => {
    return (
      <BranchOption
        key={index}
        data={item}
        isSelected={selectedBranchId === item.BranchId}
        onClick={onClick}
      />
    );
  });

  return <div className="branch-list">{itemList}</div>;
};

const BranchOptionListWrapper = props => {
  const { data, selectedBranchId, onSetSelectedBranch } = props;

  return (
    <section>
      <BranchOptionList
        data={data}
        selectedBranchId={selectedBranchId}
        onClick={onSetSelectedBranch}
      />
    </section>
  );
};

export default BranchOptionListWrapper;
