import React from "react";

const Brand = props => {
  const { dealerName, logo, isLogoLabelVisible = true } = props;

  if (isLogoLabelVisible === false) {
    return (
      <div className="brand-nolabel">
        <div className="logo">
          <img className="logo-img" src={logo} alt="Logo" />
        </div>
      </div>
    );
  }

  return (
    <div className="brand">
      <div className="logo">
        <img className="logo-img" src={logo} alt="" />
      </div>
      <span className="no-margin extra-bold">{dealerName}</span>
    </div>
  );
};

export default Brand;
