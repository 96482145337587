import React, { Component } from "react";
import StepHeader from "components/Header/StepHeader";
import ContactInformationForm from "containers/ContactInformationPage/ContactInformationForm";
import { updateCustomerContact } from "containers/ContactInformationPage/actions";
import WithLoader from "components/WithLoader/index";
import WithErrorPage from "components/WithErrorPage";
import Contact from "models/contact";
import AlertDialog from "components/AlertDialog";

export default class ContactInformation extends Component {
  state = {
    isSubmitting: false,
    isSubmitted: false,
    isError: false,
    isAlertOpen: false,

    contact: Contact()
  };

  onChange = (name, value) => {
    // This does not update the contact in the session storage, we will save it on submit
    // if the user clicks the back button, the edits will be canceled

    this.setState(prevState => {
      return { contact: prevState.contact.update(name, value, false) };
    });
  };

  onError = () => {
    this.setState({
      isSubmitting: false,
      isError: true
    });
  };

  handleSubmit = () => {
    this.state.contact.save();
    this.setState({ isSubmitting: true });

    updateCustomerContact(
      {
        dealerId: sessionStorage.getItem("dealerId"),
        customerNo: sessionStorage.getItem("customerNo")
      },
      () => {
        this.setState({ isAlertOpen: true });
      }
    ).catch(() => {
      this.onError();
    });
  };

  closeAlert = () => {
    this.setState({ isAlertOpen: false });
    this.props.history.push("/bookService/review#contact");
  };

  render() {
    const { isSubmitting, isError, isAlertOpen, contact } = this.state;

    return (
      <WithErrorPage isError={isError} buttonText={null}>
        <WithLoader isLoading={isSubmitting} message="Submitting">
          <StepHeader
            headerTitle="Your Contact Information"
            goBackLink="/bookService/review#contact"
          />
          <div className="responsive-wrapper">
            <main>
              <section>
                <ContactInformationForm
                  data={contact}
                  onChange={this.onChange}
                  handleSubmit={this.handleSubmit}
                />
              </section>
              <AlertDialog
                isOpen={isAlertOpen}
                closeAlert={this.closeAlert}
                message={`Thank you for updating your contact details. The booking confirmation email will be sent to the old email address. Our service team will update your new contact details.`}
              />
            </main>
          </div>
        </WithLoader>
      </WithErrorPage>
    );
  }
}
