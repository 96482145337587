import React from "react";

const ShoppingCartSvg = props => (
  <svg width={26} height={22} viewBox="0 0 26 22" {...props}>
    <title>shopping-cart</title>
    <path d="M9 17c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zm0 3.2c-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2zM19 17c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zm0 3.2c-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2zM24.909 5.726a.452.452 0 0 0-.362-.18H4.96L3.698 1.323A.453.453 0 0 0 3.264 1H1.453a.454.454 0 0 0 0 .91h1.474l1.261 4.218 2.716 9.542c.056.195.233.33.436.33h14.49c.202 0 .38-.135.436-.33l2.717-9.545a.456.456 0 0 0-.074-.399zm-3.42 9.365H7.68L5.223 6.455h18.724l-2.458 8.636z" />
  </svg>
);

export default ShoppingCartSvg;
