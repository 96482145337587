import React from "react";
import ConfirmationHeading from "containers/ConfirmationPage/ConfirmationHeading";
import ConfirmationSummary from "containers/ConfirmationPage/ConfirmationSummary";
import DealershipLocation from "containers/ConfirmationPage/DealershipLocation";
import { AuthConsumer } from "components/AuthContext";

class ConfirmationPage extends React.Component {
  componentWillUnmount() {
    this.props.logout();
  }

  render() {
    const appointmentId = sessionStorage.getItem("appointmentId");
    const wipNo = sessionStorage.getItem("wipNo");
    const firstName = sessionStorage.getItem("firstName");
    const branchesData = JSON.parse(
      sessionStorage.getItem("dealerBranches") || []
    );
    const branchId = JSON.parse(sessionStorage.getItem("branchId") || null);
    const branchData = branchesData.find(x => x.BranchId === branchId);
    const selectedServicesData = JSON.parse(
      sessionStorage.getItem("selectedServicesData") || []
    );
    const selectedServiceNameList = selectedServicesData.map(item => item.Name);
    const timeSlot = sessionStorage.getItem("timeSlot");
    const jobDate = sessionStorage.getItem("jobDate");
    const vehicleDesc = sessionStorage.getItem("modelName");
    const registrationNumber = sessionStorage.getItem("registrationNumber");
    const advisorName = sessionStorage.getItem("advisorName");
    const transportMethodName = sessionStorage.getItem("transportMethodName");
    const isTransportationOptionsVisible = JSON.parse(
      sessionStorage.getItem("isTransportationOptionsVisible") || null
    );
    const isTimeslotsVisible = JSON.parse(
      sessionStorage.getItem("isTimeslotsVisible") || null
    );
    const isAdvisorsVisible = JSON.parse(
      sessionStorage.getItem("isAdvisorsVisible") || null
    );

    const headingData = {
      firstName,
      wipNo
    };

    const summaryData = {
      ...headingData,
      appointmentId,
      timeSlot,
      jobDate,
      vehicleDesc,
      registrationNumber,
      advisorName,
      transportMethodName,
      branchName: branchData.BranchName,
      address: branchData.Address,
      phoneNumber: branchData.PhoneNumber,
      emailAddress: branchData.EmailAddress,
      selectedServiceNameList: selectedServiceNameList,
      isTransportationOptionsVisible,
      isTimeslotsVisible,
      isAdvisorsVisible
    };

    return (
      <div className="confirmation">
        <div className="responsive-wrapper">
          <main>
            <section className="full-width">
              <ConfirmationHeading data={headingData} />
              <ConfirmationSummary data={summaryData} />
              <DealershipLocation data={branchData} />
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default props => (
  <AuthConsumer>
    {({ isAuthenticated, logout }) => (
      <ConfirmationPage {...props} logout={logout} />
    )}
  </AuthConsumer>
);
