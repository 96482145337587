import React from "react";
import BackSvg from "components/Svg/BackSvg";
import Header from "components/Header/Header";

const GoBackButton = props => (
  <a className="icon-back" onClick={props.goBack}>
    <BackSvg />
  </a>
);

const ModalHeader = props => {
  const { goBack } = props;
  const headerLeft = <GoBackButton goBack={goBack} />;

  return <Header headerLeft={headerLeft} headerTitle="Shopping Cart" />;
};

export default ModalHeader;
