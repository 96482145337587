import React from "react";
import CarDetail from "components/CarDetail";
import CartList from "components/ShoppingCart/CartList";
import ModalHeader from "components/Header/ModalHeader";

const ShoppingCart = props => {
  const { closeModal, deleteService, data } = props;
  const distanceBasedServiceData = data.DistanceBasedServices;
  const additionalServiceData = data.AdditionalServices;

  const carDetailData = {
    modelYear: sessionStorage.getItem("modelYear"),
    modelName: sessionStorage.getItem("modelName"),
    mileage: sessionStorage.getItem("mileage"),
    isMileageVisible: JSON.parse(
      sessionStorage.getItem("isMileageVisible") || null
    ),
    odometerUnit: sessionStorage.getItem("odometerUnit")
  };
  const isPriceVisible = JSON.parse(
    sessionStorage.getItem("isPriceVisible") || null
  );
  const currencySymbol = sessionStorage.getItem("currencySymbol");

  return (
    <div className="cart">
      <ModalHeader goBack={closeModal} />
      <div className="responsive-wrapper">
        <main>
          <section className="full-width">
            <CarDetail data={carDetailData} />
            <CartList
              unDeletableData={distanceBasedServiceData}
              deletableData={additionalServiceData}
              isPriceVisible={isPriceVisible}
              deleteService={deleteService}
              currencySymbol={currencySymbol}
            />
          </section>
        </main>
      </div>
    </div>
  );
};

export default ShoppingCart;
