import React from "react";

const ArrowDownSvg = props => (
  <svg width={18} height={10} viewBox="0 0 18 10" {...props}>
    <title>{"Down Chevron"}</title>
    <path d="M9 10l9-8.4L16.286 0 9 6.8 1.714 0 0 1.6z" fillRule="evenodd" />
  </svg>
);

export default ArrowDownSvg;
