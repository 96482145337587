import React from "react";

const BackSvg = props => (
  <svg viewBox="0 0 13 21" width={13} height={21} {...props}>
    <title>{"Back"}</title>
    <path
      d="M0 10.253L10.253 0l1.952 1.953-8.3 8.3 8.3 8.3-1.952 1.952z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);

export default BackSvg;
