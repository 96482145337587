import React from "react";

const CancelSvg = props => (
  <svg width={14} height={14} viewBox="0 0 14 14" {...props}>
    <title>{"close"}</title>
    <path
      d="M14 1.4L12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7z"
      fillRule="evenodd"
    />
  </svg>
);

export default CancelSvg;
