import React from "react";
import Cleave from "cleave.js/react";

export default function(props) {
  const { data, onChange, askForComments } = props;

  function onInputChange(event)
  {
    const {name, value} = event.target;
    onChange(name, value);
  }

  return <>
    <div className="form-group">
      <label htmlFor="firstName">First Name*</label>
      <input
        className="selected"
        type="text"
        name="firstName"
        id="firstName"
        value={data.firstName || ''}
        spellCheck="false"
        onChange={onInputChange}
      />
      <span className="error">
        {data.firstNameError && "Please input your first name"}
      </span>
    </div>
    <div className="form-group">
      <label htmlFor="surname">Last Name*</label>
      <input
        className="selected"
        type="text"
        name="surname"
        id="surname"
        value={data.surname || ''}
        spellCheck="false"
        onChange={onInputChange}
      />
      <span className="error">
        {data.surnameError && "Please input your last name"}
      </span>
    </div>
    <div className="form-group">
      <label htmlFor="email">Email*</label>
      <input
        className="selected"
        type="email"
        name="email"
        id="email"
        value={data.email || ''}
        spellCheck="false"
        onChange={onInputChange}
      />
      <span className="error">
        {data.emailError && "Please input your email"}
      </span>
    </div>
    <div className="form-group">
      <label htmlFor="phoneNumber">Phone Number</label>
      <Cleave
        options={{ phone: true, phoneRegionCode: "NZ" }}
        className="selected"
        type="tel"
        name="phoneNumber"
        id="phoneNumber"
        value={data.phoneNumber || ''}
        onChange={onInputChange}
      />
    </div>
    { askForComments && 
      (
        <div className="form-group">
          <label htmlFor="additionalComments">Additional Comments?</label>
          <textarea
            name="additionalComments"
            id="additionalComments"
            cols="30"
            rows="3"
            maxLength="100"
            placeholder="Please type your comments here..."
            value={data.additionalComments}
            onChange={onInputChange}
          />
        </div> 
      ) 
    }
  </>;
};