import React from "react";
class JobNotesService extends React.Component {
  state = {
    jobComments: this.props.notes
  };

  setSelectedService = notes => {
    const { data, onSetSelectedService } = this.props;
    this.setState({ jobComments: notes });
    notes.length > 0
        ? onSetSelectedService(data.ServiceCode, notes)
        : onSetSelectedService("", notes);
   };
  render() {
    const { jobComments } = this.state;
    const { isVisible } = this.props;
    if (!isVisible) {
      return null;
    }
    return (
      <React.Fragment>
        <section>
          <div className="card contact">
            <div className="form-group">
              <label htmlFor="jobComments">Additional Job Notes:</label>
              <textarea
                name="jobComments"
                id="jobComments"
                cols="30"
                rows="3"
                maxLength="255"
                placeholder="Please type your comments here..."
                value={jobComments}
                onChange={event => {
                  this.setSelectedService(event.target.value);
                }}
              />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default JobNotesService;
