import React from "react";
import "./assets/css/main.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import ErrorBoundary from "components/ErrorBoundary";
import AppRouter from "components/Router";
import { ThemeProvider } from "components/ThemeContext";

const App = () => (
  <ErrorBoundary>
    <ThemeProvider>
      <div id="app">
        <AppRouter />
      </div>
    </ThemeProvider>
  </ErrorBoundary>
);

export default App;
