import React from "react";
import { Link } from "react-router-dom";

const OutlineButton = props => {
  const { link, onClick, text, disabled, className } = props;

  if (props.link) {
    return (
      <Link
        to={link}
        onClick={onClick}
        className="button button-medium button-secondary"
      >
        {text}
      </Link>
    );
  }

  return (
    <a
      onClick={onClick}
      className={
        "button button-medium button-secondary" +
        (disabled ? " disable" : "") +
        (className ? " " + className : "")
      }
    >
      {text}
    </a>
  );
};

export default OutlineButton;
