import React from "react";
import moment from "moment";

const ConfirmationItems = props => {
  const {
    appointmentId,
    wipNo,
    selectedServiceNameList,
    timeSlot,
    jobDate,
    advisorName,
    vehicleDesc,
    registrationNumber,
    transportMethodName,
    branchName,
    address,
    phoneNumber,
    emailAddress,
    isTransportationOptionsVisible,
    isTimeslotsVisible,
    isAdvisorsVisible
  } = props.data;

  const serviceStartTime = isTimeslotsVisible
    ? timeSlot && timeSlot !== "null"
      ? moment(timeSlot.split("-")[0], "HH:mm").format("hh:mm A")
      : null
    : "07:30 AM"; // Full day booking when "Timeslots" is not visible
  const serviceEndTime =
    timeSlot && timeSlot !== "null"
      ? moment(timeSlot.split("-")[1], "HH:mm").format("hh:mm A")
      : null;
  const serviceDate =
    jobDate && jobDate !== "null"
      ? moment(jobDate).format("DD MMM YYYY")
      : null;
  const isSyncCalendarVisible = serviceStartTime && serviceDate;

  const TimeItem = (
    <div className="confirmation__item">
      <p className="label">Time</p>
      <p className="bold">{serviceStartTime || "Not Selected"}</p>
    </div>
  );

  const DateItem = (
    <div className="confirmation__item">
      <p className="label">Date</p>
      <p className="bold">{serviceDate || "Not Selected"}</p>
    </div>
  );

  const AdvisorItem = (
    <div className="confirmation__item">
      <p className="label">Advisor</p>
      <p className="bold">
        {advisorName && advisorName !== "null"
          ? advisorName
          : "First available advisor"}
      </p>
    </div>
  );

  const VehicleItem = (
    <div className="confirmation__item">
      <p className="label">Vehicle</p>
      <p className="bold">{vehicleDesc}</p>
    </div>
  );

  const TransportationItem = (
    <div className="confirmation__item">
      <p className="label">Transportation</p>
      <p className="bold">{transportMethodName}</p>
    </div>
  );

  const SyncToYourCalendarItem = (
    <div className="confirmation__item">
      <p className="label">Sync to your calendar</p>
      {isSyncCalendarVisible ? (
        <div
          title="Add to Calendar"
          className="addeventatc"
          data-render="inline-buttons"
        >
          <span className="start">{serviceDate + " " + serviceStartTime}</span>
          <span className="end">{serviceDate + " " + serviceEndTime}</span>
          <span className="timezone"></span>
          <span className="title">Car Service Booking</span>
          <span className="description">
            Thank you for booking your vehicle {vehicleDesc}{" "}
            {registrationNumber} into our workshop.
            <br />
            <br />
            Your booking confirmation number is {wipNo}.<br />
            <br />
            <b>You have selected:</b>
            <br />
            <br />
            Services: {selectedServiceNameList.join(", ")}
            <br />
            <br />
            Appointment Type: {transportMethodName}
            <br />
            <br />
            Appointment Date: {serviceDate}
            <br />
            <br />
            Appointment Time: {timeSlot}
            <br />
            <br />
            If you need to cancel or amend your booking, please call us on{" "}
            {phoneNumber} or email at {emailAddress}
            <br />
            <br />
            We look forward to welcoming you at {branchName}, {address}.
            <br />
          </span>
          <span className="location">{address}</span>
        </div>
      ) : (
        <p className="bold">Date and time are not selected.</p>
      )}
    </div>
  );

  // refresh Add to Calendar button, wrapped in setTimeOut just in case.
  setTimeout(function() {
    window.addeventatc.refresh();
  }, 200);

  return (
    <div className="confirmation__info card card-full-width">
      <div className="confirmation__grid">
        {isTimeslotsVisible && TimeItem}
        {DateItem}
        {isAdvisorsVisible && AdvisorItem}
        {VehicleItem}
        {isTransportationOptionsVisible && TransportationItem}
        {isAdvisorsVisible && SyncToYourCalendarItem}
      </div>
      {!isAdvisorsVisible && SyncToYourCalendarItem}
    </div>
  );
};

export default ConfirmationItems;
