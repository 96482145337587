import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const groupPredefinedTimeSlotOptionsByAmPm = predefinedTimeSlotOptions => {
  let am = [];
  let pm = [];

  predefinedTimeSlotOptions &&
    predefinedTimeSlotOptions.length > 0 &&
    predefinedTimeSlotOptions.forEach(slot => {
      const startHourString = slot.split(":")[0];
      const startHourInt = parseInt(startHourString, 10);

      if (startHourInt >= 0 && startHourInt < 12) {
        am.push(slot);
      } else if (startHourInt >= 12 && startHourInt <= 24) {
        pm.push(slot);
      }
    });

  return { am, pm };
};

const TimeSlotBtnGroup = props => {
  const {
    availableTimeSlotList,
    selectedTimeSlot,
    predefinedOptions,
    onSetSelectedValue
  } = props;

  const isAvailable = (option, availableList) => {
    return availableList && availableList.includes(option);
  };

  return (
    <div className="l-grid l-three-col grid-gap-16">
      {predefinedOptions.map((predefinedOption, index) =>
        isAvailable(predefinedOption, availableTimeSlotList) ? (
          <a
            key={"timeSlot-" + index}
            className={
              "item-time available" +
              (selectedTimeSlot === predefinedOption ? " selected" : "")
            }
            onClick={() => onSetSelectedValue(predefinedOption)}
          >
            {predefinedOption.split("-")[0]}
          </a>
        ) : (
          <a key={"timeSlot-" + index} className={"item-time unavailable"}>
            {predefinedOption.split("-")[0]}
          </a>
        )
      )}
    </div>
  );
};

const TimePicker = props => {
  const {
    timeSlotData,
    selectedTimeSlot,
    onSetSelectedTimeSlot,
    isVisible,
    predefinedTimeSlotOptions
  } = props;

  if (!isVisible || !predefinedTimeSlotOptions) {
    return null;
  }

  const predefinedTimeSlotOptionsGroupedByAmPm = groupPredefinedTimeSlotOptionsByAmPm(
    predefinedTimeSlotOptions
  );

  return (
    <React.Fragment>
      <div className="form-group">
        <label htmlFor="">Select Appointment Time</label>
        <Tabs
          //default select PM tab if AM has no predefined timeslot options
          defaultIndex={
            predefinedTimeSlotOptionsGroupedByAmPm.am.length > 0 ? 0 : 1
          }
          selectedTabClassName="current"
          disabledTabClassName="disabled"
        >
          <TabList className="tabs text-center">
            <Tab
              className="tab-time"
              disabled={predefinedTimeSlotOptionsGroupedByAmPm.am.length <= 0}
            >
              AM
            </Tab>
            <Tab
              className="tab-time"
              disabled={predefinedTimeSlotOptionsGroupedByAmPm.pm.length <= 0}
            >
              PM
            </Tab>
            <hr />
          </TabList>
          <TabPanel className="tab-content">
            <TimeSlotBtnGroup
              availableTimeSlotList={timeSlotData}
              predefinedOptions={predefinedTimeSlotOptionsGroupedByAmPm.am}
              selectedTimeSlot={selectedTimeSlot}
              onSetSelectedValue={onSetSelectedTimeSlot}
            />
          </TabPanel>
          <TabPanel className="tab-content">
            <TimeSlotBtnGroup
              availableTimeSlotList={timeSlotData}
              predefinedOptions={predefinedTimeSlotOptionsGroupedByAmPm.pm}
              selectedTimeSlot={selectedTimeSlot}
              onSetSelectedValue={onSetSelectedTimeSlot}
            />
          </TabPanel>
        </Tabs>
      </div>
    </React.Fragment>
  );
};

export default TimePicker;
