import { fetchApi } from "utils/apiFetchWrapper";
import { Api, ApiSource } from "constants/api";

const fetchServiceAdvisors = (requestInput, onReceiveResponse) => {
  const dealerId = sessionStorage.getItem("dealerId");
  const isNewCustomer = JSON.parse(sessionStorage.getItem("isNewCustomer"));
  const customerNo = isNewCustomer
    ? null
    : sessionStorage.getItem("customerNo");

  const data = {
    DealerId: dealerId,
    CustomerNo: customerNo,
    AppointmentDate: requestInput.selectedDate,
    TransportMethod: requestInput.selectedTransportMethod,
    DropOffTime: requestInput.selectedTimeSlot
  };

  fetchApi(
    ApiSource.Appointment,
    Api.Appointment.Endpoint.GetServiceAdvisors,
    data
  )
    .then(response => {
      onReceiveResponse(response);
    })
    .catch(() => {});
};

export default fetchServiceAdvisors;
