import { fetchApi } from "utils/apiFetchWrapper";
import { Api, ApiSource } from "constants/api";
import { dayPickerOptionalDuration } from "constants/predefinedOptions";
import moment from "moment";

const fetchAppointmentSlots = (
  selectedDate = undefined,
  signal = undefined
) => {
  const jobCode = [
    ...JSON.parse(sessionStorage.getItem("distanceBasedServiceCodes")),
    ...JSON.parse(sessionStorage.getItem("additionalServiceCodes"))
  ];
  const dealerId = sessionStorage.getItem("dealerId");
  const customerNo = sessionStorage.getItem("customerNo");

  const data = {
    DealerId: dealerId,
    CustomerNo: customerNo,
    InitialDate:
      selectedDate === undefined ? moment().format("YYYY-MM-DD") : selectedDate,
    EndDate:
      selectedDate === undefined
        ? moment()
            .add(dayPickerOptionalDuration, "day")
            .format("YYYY-MM-DD")
        : selectedDate,
    JobCode: jobCode
  };

  return fetchApi(
    ApiSource.Appointment,
    Api.Appointment.Endpoint.GetAppointmentSlots,
    data,
    signal
  );
};

export default fetchAppointmentSlots;
