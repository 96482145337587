import React, { Component } from "react";
import StepHeader from "components/Header/StepHeader";
import ActionButton from "components/ActionButton";
import BranchOptions from "./BranchOptions";
import { Redirect } from "react-router";
import WithErrorPage from "components/WithErrorPage";

export default class SelectBranchPage extends Component {
  state = {
    selectedBranchId: null,
    data: null,
    isError: false
  };

  initializeBranchValue = () => {
    const selectedBranchId = JSON.parse(sessionStorage.getItem("branchId"));
    const data = JSON.parse(sessionStorage.getItem("dealerBranches"));
    let initialBranchId;

    if (data && data.length > 0) {
      if (selectedBranchId) {
        initialBranchId = selectedBranchId;
      } else {
        initialBranchId = data[0].BranchId;
      }
      this.setState({ data });
      this.onSetSelectedBranchIdValue(initialBranchId);
    }
  };

  onSetSelectedBranchIdValue = selectedBranchId => {
    this.setState({ selectedBranchId });
  };

  saveData = () => {
    sessionStorage.setItem(
      "branchId",
      JSON.stringify(this.state.selectedBranchId)
    );
  };

  componentDidMount() {
    this.initializeBranchValue();
  }

  render() {
    const { selectedBranchId, data, isError } = this.state;

    const newCustomer = JSON.parse(sessionStorage.getItem("isNewCustomer"));
    const goBackLink = newCustomer
      ? "/bookService/carLookup"
      : "/bookService/carDetails";

    if (data && data.length === 1) {
      this.saveData();
      return <Redirect to="/bookService/selectServices" />;
    }

    return (
      <WithErrorPage isError={isError}>
        <StepHeader
          headerTitle="Select Service Location"
          goBackLink={goBackLink}
        />
        <div className="responsive-wrapper">
          <main>
            <BranchOptions
              data={data}
              onSetSelectedBranch={this.onSetSelectedBranchIdValue}
              selectedBranchId={selectedBranchId}
            />
            <div className="fixed-footer">
              <div className="responsive-wrapper">
                <ActionButton
                  link="/bookService/selectServices"
                  onClick={this.saveData}
                  isEnabled={selectedBranchId}
                  text="Next"
                />
              </div>
            </div>
          </main>
        </div>
      </WithErrorPage>
    );
  }
}
