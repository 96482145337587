export const ApiSource = {
  CustomerVehicle: "CustomerVehicle",
  DealerConfiguration: "DealerConfiguration",
  VehicleMaintenance: "VehicleMaintenance",
  Appointment: "Appointment",
  VehicleLookup: "VehicleLookup"
};

export const Api = {
  CustomerVehicle: {
    XFunctionsKey: window.APP_DEPL_VAR.CustomerVehicleServiceKey,
    Url: window.APP_DEPL_VAR.CustomerVehicleServiceUrl,
    Endpoint: {
      DismissVehicleOwnership: "DismissVehicleOwnership",
      UpdateCustomerContact: "UpdateCustomerContact",
      CreateServiceBooking: "CreateServiceBooking"
    }
  },
  DealerConfiguration: {
    XFunctionsKey: window.APP_DEPL_VAR.DealerConfigurationServiceKey,
    Url: window.APP_DEPL_VAR.DealerConfigurationServiceUrl
  },
  VehicleMaintenance: {
    XFunctionsKey: window.APP_DEPL_VAR.VehicleMaintenanceServiceKey,
    Url: window.APP_DEPL_VAR.VehicleMaintenanceServiceUrl,
    Endpoint: {
      GetRecommendedServices: "GetRecommendedServices"
    }
  },
  Appointment: {
    XFunctionsKey: window.APP_DEPL_VAR.AppointmentServiceKey,
    Url: window.APP_DEPL_VAR.AppointmentServiceUrl,
    Endpoint: {
      GetServiceAdvisors: "GetServiceAdvisors",
      GetAppointmentSlots: "GetAppointmentSlots",
      GetAppointmentOptionsAndDates: "GetAppointmentOptionsAndDates",
      CreateAppointment: "CreateAppointment",
      CreateAppointmentForNewCustomer: "CreateAppointmentForNewCustomer",
      GetExistingAppointment: "GetExistingAppointment"
    }
  },
  VehicleLookup: {
    XFunctionsKey: window.APP_DEPL_VAR.VehicleLookupServiceKey,
    Url: window.APP_DEPL_VAR.VehicleLookupServiceUrl
  },
  GoogleService: {
    Key: window.APP_DEPL_VAR.GoogleServiceKey
  },
  Img: {
    Url: window.APP_DEPL_VAR.ImgUrl
  }
};
