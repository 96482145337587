const themes = {
  default: "theme-default",
  light: "theme-light",
  dark: "theme-dark",
  navy: "theme-navy",
  green: "theme-green",
  red: "theme-red",
  yellow: "theme-yellow",
  greengiltrap: "theme-green-giltrap",
  darkgiltrap: "theme-dark-giltrap",
  continentalcars: "theme-continentalcars",
  porsche: "theme-porsche"
};

export default themes;
