import React from "react";
import OutlineButton from "components/OutlineButton";

const CarDetailsCard = props => {
  const {
    modelYear,
    modelName,
    registrationNumber,
    vehicleImage,
    nextServiceDate,
    nextWOFDate,
    vehicleInspectionTestName
  } = props.detailData;

  const { onClickNotMyVehicleBtn } = props;

  return (
    <div className="card car-details">
      <div className="card__image">
        <img className="vehicle-image" src={vehicleImage} alt="" />
      </div>
      <div className="card__title">
        <p>
          <b>{modelYear}</b> {modelName}
        </p>
        <p className="text-grey">
          Registration Number: <b>{registrationNumber}</b>
        </p>
        {nextServiceDate && (
          <p className="text-grey">
            Next Service Date: <b>{nextServiceDate}</b>
          </p>
        )}
        {nextWOFDate && (
          <p className="text-grey">
            Next {vehicleInspectionTestName} Date: <b>{nextWOFDate}</b>
          </p>
        )}
      </div>
      <div className="card__cta">
        <OutlineButton onClick={onClickNotMyVehicleBtn} text="Not My Vehicle" />
      </div>
    </div>
  );
};

export default CarDetailsCard;
