import React from "react";

const SvgComponent = props => (
  <svg width={10} height={16} viewBox={"0 0 10 16"} {...props}>
    <title>{"location"}</title>
    <path
      d="M5 0a5 5 0 0 0-5 5c0 5 5 11 5 11s5-6 5-11a5 5 0 0 0-5-5zm0 8a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgComponent;
