import React, { Component } from "react";
import StepHeader from "components/Header/StepHeader";
import AppointmentReview from "containers/ReviewPage/AppointmentReview";
import ServiceReview from "containers/ReviewPage/ServiceReview";
import Disclaimer from "containers/ReviewPage/Disclaimer";
import CheckContactInfo from "containers/ReviewPage/CheckContactInfo";
import ActionButton from "components/ActionButton";
import { fetchApi } from "utils/apiFetchWrapper";
import { ApiSource, Api } from "constants/api";
import Enums from "constants/enum";
import ReactDOM from "react-dom";
import WithLoader from "components/WithLoader/index";
import WithErrorPage from "components/WithErrorPage";
import Contact from "models/contact";

function getNewCustomerFlagFromSS() {
  var strFlag = sessionStorage.getItem("isNewCustomer");
  var flag = false;
  if (strFlag) flag = strFlag.toLowerCase() === "true";
  return flag;
}

// collects data common for both existing and new customer and packs it to the backend call parameters structure
function prepareCommonCallData() {
  const notesServiceCode = sessionStorage.getItem("notesServiceCode");
  const jobNotes = sessionStorage.getItem("jobNotes");
  const selectedServicesData = JSON.parse(
    sessionStorage.getItem("selectedServicesData") || []
  );

  const jobs = selectedServicesData.map(item => ({
    JobCode: item.ServiceCode,
    JobDescription: item.Name
  }));

  const jobWithNotes =
    jobNotes && notesServiceCode
      ? jobs.concat({
          JobCode: notesServiceCode,
          JobComments: jobNotes
        })
      : jobs;

  const isAdvisorsVisible = JSON.parse(
    sessionStorage.getItem("isAdvisorsVisible") || null
  );
  const advisorId = isAdvisorsVisible
    ? sessionStorage.getItem("advisorId")
    : null;

  return {
    DealerId: sessionStorage.getItem("dealerId"),
    BranchId: sessionStorage.getItem("branchId"),
    ActualMileage: sessionStorage.getItem("mileage")
      ? sessionStorage.getItem("mileage")
      : null,
    JobDate: sessionStorage.getItem("jobDate"),
    Jobs: jobWithNotes,
    TransportMethod: sessionStorage.getItem("transportMethod"),
    TransportMethodDescription: sessionStorage.getItem("transportMethodName"),
    AdvisorId: advisorId,
    DropOffTime: sessionStorage.getItem("timeSlot")
  };
}

function prepareExistingCustomerCallData() {
  return Object.assign(prepareCommonCallData(), {
    CustomerNo: sessionStorage.getItem("customerNo"),
    VehicleNo: sessionStorage.getItem("vehicleNo")
  });
}

function prepareNewCustomerCallData() {
  const contact = Contact(); // populates from session storage

  return Object.assign(prepareCommonCallData(), {
    RegistrationNo: sessionStorage.getItem("registrationNumber") || "NA",
    CustomerEmail: contact.email,
    FirstName: contact.firstName,
    Surname: contact.surname,
    PhoneNumber: contact.phoneNumber,
    MakeCode: sessionStorage.getItem("makeCode") || "NA",
    ModelCode: sessionStorage.getItem("modelCode") || "NA",
    VariantCode: sessionStorage.getItem("variantCode") || "NA",
    NextWofDate: JSON.parse(sessionStorage.getItem("nextWOFDate")),
    ModelYear: sessionStorage.getItem("modelYear")
  });
}

export default class ReviewPage extends Component {
  constructor(props) {
    super(props);
    this.checkContactRef = React.createRef();

    this.state = {
      appointment: {
        timeSlot: sessionStorage.getItem("timeSlot"),
        jobDate: sessionStorage.getItem("jobDate"),
        advisorName: sessionStorage.getItem("advisorName"),
        transportMethodName: sessionStorage.getItem("transportMethodName")
      },
      contact: Contact(),
      isNewCustomer: getNewCustomerFlagFromSS(),
      wipNo: null,
      isSubmitting: false,
      isError: false
    };
  }

  onClickBookBtn = () => {
    this.setState((state, props) => {
      this.createServiceBooking(); // starts async action
      return { isSubmitting: true };
    });
  };

  onReceiveResponse = response => {
    sessionStorage.setItem("wipNo", response.WipNo);
    sessionStorage.setItem("appointmentId", response.AppointmentId);
    this.props.history.push("/bookService/confirmation");
  };

  onError = () => {
    this.setState({
      isSubmitting: false,
      isError: true
    });
  };

  createServiceBooking = () => {
    const isNewCustomer = getNewCustomerFlagFromSS();

    var data = isNewCustomer
      ? prepareNewCustomerCallData()
      : prepareExistingCustomerCallData();

    fetchApi(
      ApiSource.Appointment,
      isNewCustomer
        ? Api.Appointment.Endpoint.CreateAppointmentForNewCustomer
        : Api.Appointment.Endpoint.CreateAppointment,
      data
    )
      .then(response => {
        this.onReceiveResponse(response);
      })
      .catch(() => {
        this.onError();
      });
  };

  onChange = (name, value) => {
    // this updates the session storage every time, so prepareNewCustomerCallData() will pick it up
    this.setState(prevState => {
      return { contact: prevState.contact.update(name, value, true) };
    });
  };

  componentDidMount() {
    const hash = this.props.location.hash;
    if (hash === "#contact") {
      const domNode = ReactDOM.findDOMNode(this.checkContactRef.current);
      domNode.scrollIntoView();
    }
  }
  disableBookNow = () => {
    this.setState({ isBookNowEnabled: false });
  };

  render() {
    const {
      appointment,
      contact,
      isSubmitting,
      isError,
      isNewCustomer
    } = this.state;

    return (
      <WithErrorPage isError={isError} buttonText="Back Home">
        <WithLoader isLoading={isSubmitting} message="Submitting">
          <StepHeader
            headerTitle="Review"
            goBackLink="/bookService/appointment"
            stepNumber={Enums.ProgressBarStepNumber.Review}
          />
          <div className="responsive-wrapper review">
            <main>
              <section className="full-width">
                <AppointmentReview data={appointment} />
                <ServiceReview />
                <Disclaimer />
                <CheckContactInfo
                  data={contact}
                  isNewCustomer={isNewCustomer}
                  checkContactRef={this.checkContactRef}
                  onChange={this.onChange}
                />
                <div className="fixed-footer">
                  <div className="responsive-wrapper">
                    <ActionButton
                      onClick={this.onClickBookBtn}
                      isEnabled={!isSubmitting && !contact.isError}
                      text="Book Now"
                    />
                  </div>
                </div>
              </section>
            </main>
          </div>
        </WithLoader>
      </WithErrorPage>
    );
  }
}
