import React, { Component } from "react";
import { Api, ApiSource } from "constants/api";
import { fetchApi } from "utils/apiFetchWrapper";
import WithLoader from "components/WithLoader";
import WithErrorPage from "components/WithErrorPage";
import ActionButton from "components/ActionButton";
import OutlineButton from "components/OutlineButton";
import moment from "moment";
import MileageInputCard from "containers/CheckCarDetailsPage/MileageInput";
import StepHeader from "components/Header/StepHeader";

export default class CarLookupPage extends Component {
  state = {
    isLoading: false,
    isError: false,
    dealerName: null,
    dealerPhoneNumber: null,
    dealerId: null,
    heroImage: null,
    logoImage: null,
    regoNumberEntered: "",
    showClearSuffix: false,
    isCheckMyCarBtnEnabled: false,
    isFetchingVehicleInfo: false,
    isVehicleInfoError: null,
    mileage: "",
    vehicleInfoErrorMessage: "",
    vehicleRegoNumber: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleYear: "",
    vehicleVariant: "",
    vehicleLastOdometerReading: "",
    vehicleNextWOFDate: null,
    isVehicleInfoAvailable: false,
    isFetchingExistingAppointment: false,
    isExistingAppointmentError: false,
    existingBooking: null,
    customerNo: "",
    vehicleNo: "",
    vehicleLookupSource: null,
    odometerUnit: "",
    vehicleInspectionTestName: ""
  };

  componentDidMount() {
    this.setState({
      dealerName: sessionStorage.getItem("dealerName"),
      dealerPhoneNumber: sessionStorage.getItem("dealerPhoneNumber"),
      dealerId: sessionStorage.getItem("dealerId"),
      logoImage: sessionStorage.getItem("logoImage"),
      heroImage: sessionStorage.getItem("defaultVehicleImage"),
      mileage: sessionStorage.getItem("mileage") || "",
      isMileageVisible: JSON.parse(
        sessionStorage.getItem("isMileageVisible") || null
      ),
      vehicleRegoNumber: sessionStorage.getItem("registrationNumber"),
      vehicleMake: sessionStorage.getItem("makeCode"),
      vehicleModel: sessionStorage.getItem("modelCode"),
      vehicleYear: sessionStorage.getItem("modelYear"),
      vehicleVariant: sessionStorage.getItem("variantCode"),
      vehicleLastOdometerReading: sessionStorage.getItem("lastOdometerReading"),
      vehicleNextWOFDate: JSON.parse(
        sessionStorage.getItem("nextWOFDate") || null
      ),
      vehicleLookupSource: sessionStorage.getItem("vehicleLookupSource"),
      odometerUnit: sessionStorage.getItem("odometerUnit"),
      vehicleInspectionTestName: sessionStorage.getItem("vehicleInspectionTestName")
    });
    if (sessionStorage.getItem("registrationNumber")) {
      this.setState({
        regoNumberEntered: sessionStorage.getItem("registrationNumber"),
        regoNumberError: false,
        isCheckMyCarBtnEnabled: true,
        showClearSuffix: true,
        isVehicleInfoAvailable: true,
        isVehicleInfoError: false
      });
    }
  }

  saveData = () => {
    sessionStorage.setItem("isNewCustomer", true);
    sessionStorage.setItem("mileage", this.state.mileage);
  };

  savecarLookUpInfo = response => {
    const modelName = () => {
      return (
        (response.make ? response.make : "") +
        (response.model ? " " + response.model : "") +
        (response.subModel ? " " + response.subModel : "")
      );
    };
    sessionStorage.setItem(
      "registrationNumber",
      response.registrationNo || this.state.regoNumberEntered
    );
    sessionStorage.setItem("makeCode", response.make);
    sessionStorage.setItem("modelYear", response.year);
    sessionStorage.setItem("modelCode", response.model);
    sessionStorage.setItem("variantCode", response.subModel);
    sessionStorage.setItem("vehicleNo", response.registrationNo);
    sessionStorage.setItem("customerNo", response.registrationNo);
    sessionStorage.setItem("modelName", modelName());
    sessionStorage.setItem(
      "nextWOFDate",
      response.wofExpires
        ? '"' + moment(response.wofExpires).format("DD/MM/YYYY") + '"'
        : null
    );
    sessionStorage.setItem(
      "lastOdometerReading",
      response.lastOdometerReading ? response.lastOdometerReading : ""
    );
  };

  onReceiveVehicleInfoResponse = response => {
    this.savecarLookUpInfo(response);
    this.setState({
      isLoading: false,
      isFetchingVehicleInfo: false,
      isVehicleInfoError: false,
      isVehicleInfoAvailable: true,
      vehicleInfoErrorMessage: "",
      vehicleRegoNumber:
        response.registrationNo || this.state.regoNumberEntered,
      vehicleMake: response.make || null,
      vehicleModel: response.model || null,
      vehicleYear: response.year || null,
      vehicleVariant: response.subModel || null,
      vehicleLastOdometerReading: response.lastOdometerReading || "",
      vehicleNextWOFDate: response.wofExpires
        ? moment(response.wofExpires).format("DD/MM/YYYY")
        : null,
      mileage: response.lastOdometerReading || ""
    });
  };

  onReceiveVehicleInfoError = error => {
    this.setState({
      isLoading: false,
      isFetchingVehicleInfo: false,
      isVehicleInfoAvailable: false,
      isVehicleInfoError: true,
      vehicleInfoErrorMessage: error.message
    });
  };

  fetchVehicleInfo = (vehicleLookupSource, regoNo) => {
    const endpoint = `source/${vehicleLookupSource}/rego/${regoNo}`;
    this.setState({
      isFetchingVehicleInfo: true,
      isVehicleInfoAvailable: false,
      isLoading: true
    });
    return fetchApi(ApiSource.VehicleLookup, endpoint)
      .then(response => this.onReceiveVehicleInfoResponse(response))
      .catch(e => this.onReceiveVehicleInfoError(e));
  };

  onReceiveExistingAppointmentResponse = response => {
    this.setState(
      {
        isFetchingExistingAppointment: false,
        isExistingAppointmentError: false,
        existingBooking: response,
        isLoading: false
      },
      () => {
        if (this.state.existingBooking === null) {
          this.fetchVehicleInfo(
            this.state.vehicleLookupSource,
            this.state.regoNumberEntered
          );
        }
      }
    );
  };

  onReceiveExistingAppointmentError = error => {
    this.setState({
      isFetchingExistingAppointment: false,
      isExistingAppointmentError: true,
      isError: true,
      existingAppointmentErrorMessage: error.message,
      isLoading: false
    });
  };

  fetchExistingAppointment = (dealerId, regoNo) => {
    const endpoint = `dealers/${dealerId}/rego/${regoNo}`;
    this.setState({
      isFetchingExistingAppointment: true,
      isLoading: true
    });
    return fetchApi(ApiSource.Appointment, endpoint)
      .then(response => this.onReceiveExistingAppointmentResponse(response))
      .catch(e => this.onReceiveExistingAppointmentError(e));
  };

  onSetMileageValue = mileage => {
    this.setState({ mileage });
  };

  onSetRegoNumberValue = regoNumber => {
    const regoNumberRegex = /^([A-Z]|[a-z]|[0-9])*$/;
    const validateRego = regoNo => {
      if (!regoNo) return false;
      else return regoNumberRegex.test(regoNo);
    };
    let { regoNumberEntered } = this.state;
    this.setState({
      isVehicleInfoAvailable: false,
      isVehicleInfoError: false
    });
    if (!regoNumber)
      this.setState({
        regoNumberEntered: regoNumber,
        isCheckMyCarBtnEnabled: false,
        regoNumberError: false,
        showClearSuffix: false
      });
    if (regoNumber) {
      this.setState(
        {
          regoNumberEntered: validateRego(regoNumber)
            ? regoNumber
            : regoNumberEntered,
          regoNumberError: validateRego(regoNumber) ? false : true
        },
        () => {
          this.setState({
            isCheckMyCarBtnEnabled:
              this.state.regoNumberEntered.length >= 2 &&
              !this.state.regoNumberError
                ? true
                : false,
            showClearSuffix:
              this.state.regoNumberEntered.length > 0 ? true : false
          });
        }
      );
    }
  };

  onClickCheckMyCarBtn = () => {
    this.fetchExistingAppointment(
      this.state.dealerId,
      this.state.regoNumberEntered
    );
  };

  onClickClearBtn = () => {
    this.setState({ existingBooking: null }, () =>
      this.onSetRegoNumberValue("")
    );
  };

  render() {
    const {
      isVehicleInfoError,
      dealerName,
      dealerPhoneNumber,
      isLoading,
      isError,
      regoNumberEntered,
      isCheckMyCarBtnEnabled,
      vehicleYear,
      vehicleMake,
      vehicleModel,
      vehicleVariant,
      vehicleRegoNumber,
      vehicleLastOdometerReading,
      vehicleNextWOFDate,
      isVehicleInfoAvailable,
      showClearSuffix,
      mileage,
      isMileageVisible,
      heroImage,
      existingBooking,
      regoNumberError,
      odometerUnit,
      vehicleInspectionTestName
    } = this.state;

    const resultSection = () => {
      if (existingBooking !== null)
        return (
          <div className="card">
            <div className="card__title">
              <p>
                Looks like you have already made a booking for this car, booking
                reference: {existingBooking}. If you want to modify this
                booking, please contact {dealerName} on{" "}
                <a
                  href={"tel:" + dealerPhoneNumber}
                  className="text-primary-color"
                >
                  {dealerPhoneNumber}
                </a>
              </p>
            </div>
          </div>
        );

      if (isVehicleInfoError === true) {
        return (
          <div className="card">
            <div className="card__title">
              <p>
                We can not find your vehicle <b>{regoNumberEntered}</b> details,
                please try with a valid vehicle registration number or please
                contact <b>{dealerName}</b> on{" "}
                <b>
                  <a
                    href={"tel:" + dealerPhoneNumber}
                    className="text-primary-color"
                  >
                    {dealerPhoneNumber}
                  </a>
                </b>
                {" to make a booking."}
              </p>
            </div>
          </div>
        );
      } else if (isVehicleInfoAvailable) {
        return (
          <>
            <div className="card result">
              <div className="card__title">
                <h2 className="semi-bold title">Your car's details</h2>
              </div>
              <div className="card__cta">
                <p>
                  <b>{vehicleYear}</b> {vehicleMake} {vehicleModel}{" "}
                  {vehicleVariant}
                </p>
                <p className="text-grey">
                  Registration Number: <b>{vehicleRegoNumber}</b>
                </p>
                {vehicleLastOdometerReading && (
                  <p className="text-grey">
                    Last Odometer Reading: <b>{vehicleLastOdometerReading}</b>
                  </p>
                )}
                {vehicleNextWOFDate && (
                  <p className="text-grey">
                    Next {vehicleInspectionTestName} Date: <b>{vehicleNextWOFDate}</b>
                  </p>
                )}
              </div>
            </div>
            <MileageInputCard
              defaultValue={mileage}
              onChange={this.onSetMileageValue}
              isVisible={isMileageVisible}
              odometerUnit={odometerUnit}
            />
          </>
        );
      }
    };

    const queryString = sessionStorage.getItem("queryString");

    return (
      <WithErrorPage isError={isError}>
        <WithLoader isLoading={isLoading}>
          <StepHeader
            headerTitle="Confirm Your Car's Details"
            goBackLink={"/" + queryString}
          />
          <div className="responsive-wrapper car-lookup">
            <main>
              <section>
                <div className="card search">
                  <div className="card__image">
                    <img
                      className="vehicle-image"
                      src={Api.Img.Url + heroImage}
                      alt="hero image"
                    />
                  </div>
                  <div className="card__title">
                    <label
                      htmlFor="regoNumber"
                      className="semi-bold rego-number-label"
                    >
                      What's your registration number?
                    </label>
                    <div className="suffixInput">
                      <input
                        className="rego-input"
                        type="text"
                        name="regoNumber"
                        id="regoNumber"
                        value={regoNumberEntered}
                        spellCheck="false"
                        placeholder="ABC123"
                        onChange={event => {
                          this.onSetRegoNumberValue(event.target.value);
                        }}
                      />
                      <a
                        className={`suffix${showClearSuffix ? " visible" : ""}`}
                        onClick={this.onClickClearBtn}
                      >
                        {"✕"}
                      </a>
                    </div>
                    <span className="error">
                      {regoNumberError &&
                        "Registration number can only be alphanumeric."}
                    </span>
                  </div>
                  <div className="card__cta">
                    <OutlineButton
                      onClick={this.onClickCheckMyCarBtn}
                      text="Check my car"
                      disabled={!isCheckMyCarBtnEnabled}
                    />
                  </div>
                </div>
                {resultSection()}
                <ActionButton
                  link="/bookService/selectBranch"
                  onClick={this.saveData}
                  isEnabled={
                    !isLoading &&
                    !isError &&
                    !isVehicleInfoError &&
                    isVehicleInfoAvailable &&
                    !isNaN(mileage) &&
                    existingBooking === null
                  }
                  text="Book Appointment"
                />
              </section>
            </main>
          </div>
        </WithLoader>
      </WithErrorPage>
    );
  }
}
