import React from "react";
import OutlineButton from "components/OutlineButton";
import CustomerContactEdit from "components/CustomerContactEdit";

const CheckContactInfo = props => {
  const { data, checkContactRef, isNewCustomer, onChange } = props;
  return (
    <React.Fragment>
      <h3 className="text-center">
        {isNewCustomer ? "Enter" : "Check"} Your Contact Information
      </h3>
      <div ref={checkContactRef} className="card contact">
        {isNewCustomer ? (
          <CustomerContactEdit data={data} onChange={onChange} />
        ) : (
          <>
            <div className="contact-list">
              <div className="list__item">
                <p>First Name:</p>
                <p className="bold">{data.firstName}</p>
              </div>
              <div className="list__item">
                <p>Last Name:</p>
                <p className="bold">{data.surname}</p>
              </div>
              <div className="list__item">
                <p>Email:</p>
                <p className="bold">{data.email}</p>
              </div>
              <div className="list__item">
                <p>Phone Number:</p>
                <p className="bold">{data.phoneNumber}</p>
              </div>
              <span className="error">
                {data.isError &&
                  "Please update your contact information to continue with your booking."}
              </span>
            </div>
            <div className="card__cta">
              <p className="text-grey">Not Correct?</p>
              <OutlineButton
                link="/bookService/contactInformation"
                text="Edit"
              />
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default CheckContactInfo;
