import React from "react";
import { Link } from "react-router-dom";
import Header from "components/Header/Header";
import BackSvg from "components/Svg/BackSvg";
import TickSvg from "components/Svg/TickSvg";
import Enums from "constants/enum";

const GoBackButton = props => (
  <Link to={props.goBackLink} className="icon-back">
    <BackSvg />
  </Link>
);

const ProgressBar = props => {
  const { stepNumber } = props;

  return (
    <div className="progress">
      <div>
        <span
          className={
            "progress__number" +
            (stepNumber === Enums.ProgressBarStepNumber.SelectServices
              ? " selected"
              : "")
          }
        >
          {Enums.ProgressBarStepNumber.SelectServices}
        </span>
        <span
          className={
            "progress__number" +
            (stepNumber === Enums.ProgressBarStepNumber.Appointment
              ? " selected"
              : "")
          }
        >
          {Enums.ProgressBarStepNumber.Appointment}
        </span>
        <span
          className={
            "progress__number" +
            (stepNumber === Enums.ProgressBarStepNumber.Review
              ? " selected"
              : "")
          }
        >
          <TickSvg />
        </span>
      </div>
    </div>
  );
};

const StepHeader = props => {
  const { goBackLink, headerTitle, stepNumber, headerRight } = props;
  const headerLeft = <GoBackButton goBackLink={goBackLink} />;
  const progressBar = stepNumber && <ProgressBar stepNumber={stepNumber} />;

  return (
    <Header
      headerLeft={headerLeft}
      headerTitle={headerTitle}
      progressBar={progressBar}
      headerRight={headerRight}
    />
  );
};

export default StepHeader;
