import React, { Component } from "react";
import StepHeader from "components/Header/StepHeader";
import ShoppingCartSvg from "components/Svg/ShoppingCartSvg";
import DistanceBasedServices from "containers/SelectServicesPage/DistanceBasedServices";
import AdditionalServices from "containers/SelectServicesPage/AdditionalServices";
import UncategorisedServices from "containers/SelectServicesPage/UncategorisedServices";
import JobNotesService from "containers/SelectServicesPage/JobNotesService";
import ActionButton from "components/ActionButton";
import Enums from "constants/enum";
import fetchRecommendedServices from "./fetchRecommendedServices";
import ShoppingCartModal from "containers/ShoppingCart/ShoppingCartModal";
import { saveData } from "containers/SelectServicesPage/saveData";
import WithLoader from "components/WithLoader/index";
import WithErrorPage from "components/WithErrorPage";

export default class SelectServicesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShoppingCartOpen: false,
      isLoading: true,
      isError: false,
      isServiceListCategorised: JSON.parse(
        sessionStorage.getItem("isServiceListCategorised") || true
      ),
      isAdditionalServicesVisible: JSON.parse(
        sessionStorage.getItem("isAdditionalServicesVisible") || null
      ),
      isShoppingCartVisible: JSON.parse(
        sessionStorage.getItem("isShoppingCartVisible") || null
      ),
      distanceBasedServiceData: [],
      selectedDistanceBasedServices: [],
      additionalServiceData: [],
      selectedAdditionalServices: [],
      selectedNotesService: "",
      isJobNotesVisible: false,
      jobNotes: null,
      notesService: null
    };
  }

  onReceiveResponse = response => {
    const distanceBasedServices = response.DistanceBasedServices;
    const additionalServices = response.AdditionalServices || [];
    const notesService = response.NotesService || null;

    const stringifiedDistanceBasedServicesHistoryValues = sessionStorage.getItem(
      "distanceBasedServiceCodes"
    );
    const selectedDistancebasedServicesHistoryValues = stringifiedDistanceBasedServicesHistoryValues
      ? JSON.parse(stringifiedDistanceBasedServicesHistoryValues)
      : [];
    const distanceBasedServicesFirstValue =
      distanceBasedServices.length > 0
        ? Array.of(distanceBasedServices[0].ServiceCode)
        : [];
    const stringifiedAdditionalServicesHistoryValues = sessionStorage.getItem(
      "additionalServiceCodes"
    );
    const selectedAdditionalServicesInitialValues = stringifiedAdditionalServicesHistoryValues
      ? JSON.parse(stringifiedAdditionalServicesHistoryValues)
      : [];
    const selectedDistanceBasedServicesInitialValues =
      selectedDistancebasedServicesHistoryValues.length > 0
        ? selectedDistancebasedServicesHistoryValues
        : !this.state.isServiceListCategorised &&
          selectedAdditionalServicesInitialValues.length > 0
        ? []
        : distanceBasedServicesFirstValue;
    const selectedNotesServicesHistoryValue = sessionStorage.getItem(
      "notesServiceCode"
    );
    const isJobNotesVisibleInitialValue = notesService ? true : false;
    const selectedNotesServiceInitialValues =
      selectedNotesServicesHistoryValue || "";
    const jobNotesHistoryValue = sessionStorage.getItem("jobNotes");
    const jobNotesInitialValue = jobNotesHistoryValue || "";
    this.setState({
      isLoading: false,
      distanceBasedServiceData: distanceBasedServices,
      additionalServiceData: additionalServices,
      notesServiceData: notesService,
      selectedDistanceBasedServices: selectedDistanceBasedServicesInitialValues,
      selectedAdditionalServices: selectedAdditionalServicesInitialValues,
      selectedNotesService: selectedNotesServiceInitialValues,
      jobNotes: jobNotesInitialValue,
      isJobNotesVisible: isJobNotesVisibleInitialValue
    });
  };

  onError = () => {
    this.setState({
      isLoading: false,
      isError: true
    });
  };

  onSetSelectedDistanceBasedService = selectedDistanceBasedService => {
    this.setState({
      selectedDistanceBasedServices: Array.of(selectedDistanceBasedService)
    });
  };

  onSetSelectedUncategorisedServices = selectedService => {
    if (
      this.state.distanceBasedServiceData.find(
        item => item.ServiceCode === selectedService
      )
    ) {
      const selectedDistanceBasedServices = this.state
        .selectedDistanceBasedServices;

      let newSelectedDistanceBasedServices = [];

      if (selectedDistanceBasedServices.includes(selectedService)) {
        newSelectedDistanceBasedServices = selectedDistanceBasedServices.filter(
          item => item !== selectedService
        );
      } else {
        newSelectedDistanceBasedServices = selectedDistanceBasedServices.concat(
          selectedService
        );
      }
      this.setState({
        selectedDistanceBasedServices: newSelectedDistanceBasedServices
      });
    } else if (
      this.state.additionalServiceData.find(
        item => item.ServiceCode === selectedService
      )
    ) {
      this.onSetSelectedAdditionalServices(selectedService);
    }
  };

  onSetSelectedAdditionalServices = selectedAdditionalService => {
    const selectedAdditionalServices = this.state.selectedAdditionalServices;
    let newSelectedAdditionalServices = [];
    if (selectedAdditionalServices.includes(selectedAdditionalService)) {
      newSelectedAdditionalServices = selectedAdditionalServices.filter(
        item => item !== selectedAdditionalService
      );
    } else {
      newSelectedAdditionalServices = selectedAdditionalServices.concat(
        selectedAdditionalService
      );
    }

    this.setState({
      selectedAdditionalServices: newSelectedAdditionalServices
    });
  };

  onSetSelectedNotesService = (selectedNotesServices, notes) => {
    this.setState({
      jobNotes: notes,
      selectedNotesService: selectedNotesServices
    });
  };

  onDeleteSelectedAdditionalService = toDeleteAdditionalService => {
    const selectedAdditionalServices = this.state.selectedAdditionalServices;
    const newSelectedAdditionalServices = selectedAdditionalServices.filter(
      item => item !== toDeleteAdditionalService
    );

    this.setState(() => ({
      selectedAdditionalServices: newSelectedAdditionalServices
    }));
  };

  openModal = () => {
    this.setState({ isShoppingCartOpen: true });
  };

  closeModal = () => {
    this.setState({ isShoppingCartOpen: false });
  };

  componentDidMount() {
    fetchRecommendedServices()
      .then(response => this.onReceiveResponse(response))
      .catch(() => this.onError());
  }

  render() {
    const {
      isShoppingCartOpen,
      isLoading,
      isError,
      isShoppingCartVisible,
      isAdditionalServicesVisible,
      isServiceListCategorised,
      distanceBasedServiceData,
      selectedDistanceBasedServices,
      additionalServiceData,
      selectedAdditionalServices,
      notesServiceData,
      isJobNotesVisible,
      jobNotes
    } = this.state;

    const uncategorisedServiceData = isAdditionalServicesVisible
      ? [...distanceBasedServiceData, ...additionalServiceData]
      : [...distanceBasedServiceData];

    const selectedServices = isAdditionalServicesVisible
      ? [...selectedDistanceBasedServices, ...selectedAdditionalServices]
      : [...selectedDistanceBasedServices];

    const itemAmount =
      selectedDistanceBasedServices.length + selectedAdditionalServices.length;

    const selectedServicesData = {
      DistanceBasedServices: distanceBasedServiceData.filter(item =>
        selectedDistanceBasedServices.includes(item.ServiceCode)
      ),
      AdditionalServices: additionalServiceData.filter(item =>
        selectedAdditionalServices.includes(item.ServiceCode)
      )
    };

    const selectedDistanceBasedService = selectedDistanceBasedServices[0];

    const ShoppingCartButton = isShoppingCartVisible && (
      <a onClick={this.openModal} className="shopping-cart-toggle">
        <ShoppingCartSvg />
        <span className="icon-shopping-cart-badge">{itemAmount}</span>
      </a>
    );

    const branchesData = JSON.parse(sessionStorage.getItem("dealerBranches"));
    const isNewCustomer = JSON.parse(sessionStorage.getItem("isNewCustomer"));
    const goBackLink =
      branchesData.length > 1
        ? "/bookService/selectBranch"
        : isNewCustomer
        ? "/bookService/carLookup"
        : "/bookService/carDetails";

    return (
      <WithErrorPage isError={isError}>
        <StepHeader
          headerTitle="Select Services"
          goBackLink={goBackLink}
          stepNumber={Enums.ProgressBarStepNumber.SelectServices}
          headerRight={ShoppingCartButton}
        />
        <div className="responsive-wrapper">
          <main>
            <WithLoader isLoading={isLoading} isContentShown={false}>
              {isServiceListCategorised === true && (
                <>
                  <DistanceBasedServices
                    data={distanceBasedServiceData}
                    selectedService={selectedDistanceBasedService}
                    onSetSelectedService={
                      this.onSetSelectedDistanceBasedService
                    }
                  />
                  <AdditionalServices
                    data={additionalServiceData}
                    selectedServices={selectedAdditionalServices}
                    onSetSelectedServices={this.onSetSelectedAdditionalServices}
                    isVisible={isAdditionalServicesVisible}
                  />
                </>
              )}
              {isServiceListCategorised === false && (
                <UncategorisedServices
                  data={uncategorisedServiceData}
                  selectedServices={selectedServices}
                  onSetSelectedServices={
                    this.onSetSelectedUncategorisedServices
                  }
                />
              )}
              <JobNotesService
                data={notesServiceData}
                notes={jobNotes}
                onSetSelectedService={this.onSetSelectedNotesService}
                isVisible={isJobNotesVisible}
              />
              <div className="fixed-footer">
                <div className="responsive-wrapper">
                  {isServiceListCategorised === false && (
                    <ActionButton
                      link="/bookService/appointment"
                      onClick={() => saveData(this.state, selectedServicesData)}
                      isEnabled={
                        selectedDistanceBasedServices[0] ||
                        selectedAdditionalServices[0]
                      }
                      text="Book Appointment"
                    />
                  )}
                  {isServiceListCategorised === true && (
                    <ActionButton
                      link="/bookService/appointment"
                      onClick={() => saveData(this.state, selectedServicesData)}
                      isEnabled={selectedDistanceBasedServices[0]}
                      text="Book Appointment"
                    />
                  )}
                </div>
              </div>
            </WithLoader>
          </main>
        </div>
        <ShoppingCartModal
          isOpen={isShoppingCartOpen}
          closeModal={this.closeModal}
          data={selectedServicesData}
          deleteService={this.onDeleteSelectedAdditionalService}
        />
      </WithErrorPage>
    );
  }
}
