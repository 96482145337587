import React from "react";
import { calendarDaySize } from "utils/responsiveUtils";
import { DayPickerSingleDateController } from "react-dates";
import Enums from "constants/enum";
import moment from "moment";

const onDateChange = (moment, dateData, onSetSelectedDate) => {
  const date = moment.format("YYYY-MM-DD");
  if (dateData && date in dateData) {
    onSetSelectedDate(date);
  }
};

const dayAvailabilityType = (date, dateData) => {
  if (dateData && date in dateData) {
    if (dateData[date].availabilityType === Enums.DateAvailabilityType.Full) {
      return "FullyAvailable";
    }
    if (
      dateData[date].availabilityType === Enums.DateAvailabilityType.Partial
    ) {
      return "PartiallyAvailable";
    }
  }
  return "NotAvailable";
};

const DayContents = (moment, dateData) => (
  <div
    className={
      "DayContent " + dayAvailabilityType(moment.format("YYYY-MM-DD"), dateData)
    }
  >
    <div className="DayText">{moment.format("D")}</div>
    <div className="DotMarker">&#11044;</div>
  </div>
);

const InitialVisibleMonth = (dateData, selectedDate) => {
  const [firstAvailableDate] = Object.keys(dateData);
  let initialVisibleMonthValue = moment(new Date());
  if (selectedDate) initialVisibleMonthValue = moment(selectedDate);
  else if (firstAvailableDate)
    initialVisibleMonthValue = moment(firstAvailableDate);
  return initialVisibleMonthValue;
};

const DayPicker = props => {
  const { datePickerKey, dateData, selectedDate, onSetSelectedDate } = props;
  return (
    <div className="form-group" key={datePickerKey}>
      {/*datePickerKey is incremented by the parent component AppointmentPage on initializeDayPickerState
      This update to datePickerKey will force the parent component to re-render and also its child component DayPickerSingleDateController.
      When DayPickerSingleDateController is rendered again it will call the InitialVisibleMonth method which will set
      the initialVisibleMonth of DayPickerSingleDateController property based on the selectedDate or the firstAvailableDate value.
      */}
      <label htmlFor="">Select Date</label>
      <div className="DayPickerWrapper">
        {dateData ? (
          <DayPickerSingleDateController
            initialVisibleMonth={() =>
              InitialVisibleMonth(dateData, selectedDate)
            }
            onDateChange={moment =>
              onDateChange(moment, dateData, onSetSelectedDate)
            }
            date={selectedDate ? moment(selectedDate) : null}
            noBorder={true}
            showKeyboardShortcuts={false}
            hideKeyboardShortcutsPanel={true}
            daySize={calendarDaySize}
            numberOfMonths={1}
            renderDayContents={moment => DayContents(moment, dateData)}
            focused={true}
          />
        ) : (
          <p className="datePickerNoValueDesc">No available dates.</p>
        )}
      </div>
    </div>
  );
};

export default DayPicker;
