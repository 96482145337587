import React from "react";

const OilSvg = props => (
  <svg width={40} height={17} viewBox="0 0 40 17" {...props}>
    <title>{"oil"}</title>
    <g fillRule="nonzero">
      <path d="M36.862 3.851a.666.666 0 1 0 .943-.942L36.47 1.575a.668.668 0 0 0-.84-.083l-1.987 1.325-10.17 6.357-2.961-3.554A.669.669 0 0 0 20 5.38h-6.667v-2h1.334a.666.666 0 1 0 0-1.333h-4a.666.666 0 1 0 0 1.333H12v2H6.667v-2a.668.668 0 0 0-.297-.555L2.37.16a.666.666 0 0 0-.99.307L.048 3.8c-.119.3-.007.642.266.813l5.02 3.137v7.63c0 .369.298.667.667.667h20c.223 0 .43-.111.555-.297L34.48 3.862l1.434-.957.947.946zM39.652 11.524l-.216-.34c-.29-.455-.565-.886-.792-1.34l-.714-1.428c-.227-.452-.968-.452-1.193 0l-.572 1.144c-.304.608-.619 1.236-1.027 1.766-.28.361-.432.755-.465 1.205-.093 1.303.829 2.53 2.1 2.792.185.038.372.057.557.057A2.664 2.664 0 0 0 40 12.714c0-.424-.117-.824-.348-1.19z" />
    </g>
  </svg>
);

export default OilSvg;
