import React, { Fragment } from "react";
import iconPhone from "assets/img/icon-phone.svg";
import iconLocation from "assets/img/icon-location.svg";
import { Api } from "constants/api";

const DealershipLocation = props => {
  const {
    BranchName: branchName,
    Address: address,
    PhoneNumber: phoneNumber,
    Longitude: longitude,
    Latitude: latitude,
    BranchPhoto: branchPhoto
  } = props.data;

  return (
    <Fragment>
      <h3 className="text-center">{branchName} Location</h3>
      <div className="google-map">
        {branchPhoto ? (
          <img src={Api.Img.Url + branchPhoto} alt="" />
        ) : (
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?size=540x220&zoom=17&maptype=roadmap&markers=color:red%7C${latitude},${longitude}&key=${
              Api.GoogleService.Key
            }`}
            alt=""
          />
        )}
      </div>
      <div className="address card card-full-width">
        <div className="address__item">
          <span>
            <img src={iconLocation} alt="" />
          </span>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
              address
            )}`}
          >
            {address}
          </a>
        </div>
        <div className="address__item">
          <span>
            <img src={iconPhone} alt="" />
          </span>
          <a href={"tel:" + phoneNumber}>{phoneNumber}</a>
        </div>
      </div>
    </Fragment>
  );
};

export default DealershipLocation;
